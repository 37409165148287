<template>
  <div class="Cargando">
    <div v-show="loading" :class="['modal_cargando', { 'show': loading }]">
      <div class="spinner"></div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
        class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <form class="search-box position-relative text-start">
          <select v-model="group_id"
                  class="form-control shadow-none rounded-0 text-black">
            <option disabled value="0">Seleccione un rol</option>
            <option v-for="group in gruposByName" :value="group.id">{{ group.name }}</option>
          </select>
        </form>
      </div>
      <div class="d-sm-flex align-items-center mt-10 mt-lg-0">
        <form class="search-box position-relative text-start">
          <select v-model="permission" :disabled="group_id===0"
                  class="form-control shadow-none rounded-0 text-black">
            <option disabled value="0">Seleccione un permiso</option>
            <option v-for="group in permisosByGroup" :value="group.id" v-show="VerificarPermiso(group)">{{
                group.description
              }}
            </option>
          </select>
        </form>
      </div>
      <div class="d-sm-flex align-items-center mt-10 mt-lg-0">
        <button
            @click="Add"
            class="bg-primary position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block d-inline-block text-decoration-none"
        >
          Agregar permiso al rol
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
        </button>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
          <tr>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Nombre
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Descripci&oacute;n
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
            >
              Acciones
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in permisosByName" :key="item.id">
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.name }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.description }}
            </td>
            <td class="shadow-none lh-1 fw-bold text-body-tertiary">
              <div class="btn-group" role="group">
                <button data-bs-toggle="tooltip" @click="Actualizar(item)"
                        data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Editar permiso" data-container="body" data-animation="true"
                        class="btn text-info">
                  <i class="flaticon-pen d-flex justify-content-center align-items-center "></i></button>
                <button :disabled="group_id===0" data-bs-toggle="tooltip" @click="Remove(item.id)"
                        data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Quitar permiso" data-container="body" data-animation="true"
                        class="btn text-danger">
                  <i class="flaticon-delete d-flex justify-content-center align-items-center "></i></button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="Actualizar text-dark">
    <div v-if="Actualizar_Modal" :class="['modal', { 'show': Actualizar_Modal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start">
            Actualizar permiso
            <i @click="Close_update" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Nombre</label>
            <input :class="name===''?'is-invalid':''" id="firstName" autofocus v-model="name"
                   class="form-control shadow-none rounded-0 text-black" readonly>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Descripci&oacute;n</label>
            <input :class="description===''?'is-invalid':''" id="firstName" autofocus v-model="description"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
        </div>
        <div class="row mb-3">
          <div v-show="error_vacios" class="col-12 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                No pueden haber campos vac&iacute;os
              </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="Close_update" class="btn btn-secondary d-block w-100" type="button">Cancelar</button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="Editar" class="btn btn-danger d-block w-100" type="button">Actualizar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import {
  agregarPermiso,
  createPermission,
  deletePermission, quitarPermiso, searchGroups,
  searchPermissions,
  searchPermissionsByGroup,
  updatePermission
} from "@/actions";
import {decodeBase64} from "@/util";

export default {
  name: "PermisosList",
  data() {
    return {
      permission: 0,
      group_id: 0,
      permisosByName: [],
      permisosByGroup: [],
      gruposByName: [],
      search: "",
      Nuevo_Modal: false,
      Actualizar_Modal: false,
      name: '',
      description: '',
      id: 0,
      phone: "",
      error_vacios: false,
      loading: false,
    }
  },
  watch: {
    group_id() {
      this.loadPermissions()
    }
  },
  mounted() {
    this.loadPermissions();
    this.loadPermissionsAll();
    this.loadGroups();
  },
  methods: {
    Close_nuevo() {
      this.name = ''
      this.description = ''
      this.Nuevo_Modal = false
    },
    Actualizar(item) {
      this.id = item.id
      this.name = item.name
      this.description = item.description
      this.Actualizar_Modal = true
    },
    Close_update() {
      this.name = ''
      this.description = ''
      this.Actualizar_Modal = false
    },
    loadPermissions() {
      this.loading = true;
      searchPermissionsByGroup({id: decodeBase64(this.group_id)}).then((resp) => {
        this.permisosByName = resp.data.data.permisosByGroup;
      }).catch(err => console.log(err)).finally(() => {
        this.loading = false;
      })
    },
    loadPermissionsAll() {
      this.loading = true;
      searchPermissions({name: this.search}).then((resp) => {
        this.permisosByGroup = resp.data.data.permisosByName;
      }).catch(err => console.log(err)).finally(() => {
        this.loading = false;
      })
    },
    Add() {
      agregarPermiso({
        groupId: decodeBase64(this.group_id),
        permissionId: this.permission
      }).then(response => {
        if (response.data.data.agregarPermiso.success) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Operación realizada con éxito"
          });
          this.permission = 0
          this.loadPermissions();
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: response.data.data.agregarPermiso.error
          });
        }
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          customClass: {
            popup: 'bg-white',
            title: 'text-dark'
          },
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: error
        });
      })
    },
    Remove(id) {
      quitarPermiso({
        groupId: decodeBase64(this.group_id),
        permissionId: id
      }).then(response => {
        if (response.data.data.quitarPermiso.success) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Operación realizada con éxito"
          });
          this.loadPermissions();
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: response.data.data.quitarPermiso.error
          });
        }
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          customClass: {
            popup: 'bg-white',
            title: 'text-dark'
          },
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: error
        });
      })
    },
    VerificarPermiso(item) {
      let verificar = true
      this.permisosByName.forEach(element => {
        if (element.id === item.id) {
          verificar = false
        }
      })
      return verificar
    },
    Editar() {
      if (this.name === '' || this.description === '') {
        this.error_vacios = true
        return false
      }
      this.error_vacios = false;
      updatePermission({
        id: this.id,
        name: this.name,
        description: this.description
      }).then(response => {
        if (response.data.data.editarPermiso.success) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Operación realizada con éxito"
          });
          this.Close_update();
          this.loadPermissions();
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: response.data.data.editarPermiso.error
          });
          this.Close_update()
        }
      }).catch(error => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          customClass: {
            popup: 'bg-white',
            title: 'text-dark'
          },
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: error
        });
      })
    },
    Eliminar(id) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });
      swalWithBootstrapButtons.fire({
        title: "Está seguro?",
        text: "Esta acción no se puede deshacer!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, eliminar!",
        cancelButtonText: "No, cancelar!",
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          deletePermission({
            id: id
          }).then(response => {
            if (response.data.data.eliminarPermiso.success) {
              swalWithBootstrapButtons.fire({
                title: "Eliminado!",
                text: "Ha eliminado el registro correctamente.",
                icon: "success"
              });
              this.loadPermissions();
            } else {
              swalWithBootstrapButtons.fire({
                title: "Error!",
                text: response.data.data.eliminarPermiso.error,
                icon: "error"
              });
            }
          }).catch(error => {
            swalWithBootstrapButtons.fire({
              title: "Error!",
              text: error,
              icon: "error"
            });
          })
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire({
            title: "Cancelado",
            text: "No se ha eliminado el registro",
            icon: "error"
          });
        }
      });
    },
    loadGroups() {
      this.loading = true;
      searchGroups(this.search).then(resp => {
        this.gruposByName = resp.data.data.searchExtendGroups.edges.map(e => e.node);
      }).catch(err => console.log(err)).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>
<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  padding: 20px;
  width: 50%;
}

@media (max-width: 767px) {
  .modal-content {
    border-radius: 15px;
    padding: 10px;
    width: 90%;
  }
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

</style>
