<template>
  <div class="Cargando">
    <div v-show="loading || cargando || loadingDif"
         :class="['modal_cargando', { 'show': loading || cargando || loadingDif }]">
      <div class="spinner"></div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
        class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <form class="search-box position-relative" @submit.prevent="()=>loadDatas(init=true)">
          <input
              type="text" v-model="search"
              @keyup.enter="()=>loadDatas(init=true)"
              @change="()=>loadDatas(init=true)"
              class="form-control shadow-none text-black rounded-0 border-0"
              placeholder="Buscar contrato"
          />
          <button class="bg-transparent text-primary transition p-0 border-0">
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
      </div>
      <div class="d-sm-flex align-items-center">

      </div>
      <div class="d-sm-flex align-items-center mt-10 mt-lg-0">

      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
          <tr>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              N&uacute;mero de contrato
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Cliente
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Fecha de salida
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Fecha de regreso
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              C&oacute;digo de promoci&oacute;n
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Monto descontado por promoci&oacute;n
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              IVA
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Monto pagado
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
            >
              Acciones
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in items" :key="item.id">
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.contratoNo }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.cliente.user.email }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ formatearFecha(item.fechaSalida) }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ formatearFecha(item.fechaRegreso) }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.promocion ? item.promocion.codigo : "" }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              $ {{ numeroFormateado(item.totalPromocion) }} MXN
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              $ {{ numeroFormateado(item.iva) }} MXN
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              $ {{ numeroFormateado(item.total) }} MXN
            </td>
            <td class="shadow-none lh-1 fw-bold text-body-tertiary">
              <div class="btn-group" role="group">
                <!--                <button data-bs-toggle="tooltip"-->
                <!--                        @click="openUpdate(item)"-->
                <!--                        v-if="item.estado!=='Cancelado'&& item.estado!=='Cerrado'"-->
                <!--                        data-bs-placement="top"-->
                <!--                        style="padding: 0.8rem"-->
                <!--                        title="Editar contrato" data-container="body" data-animation="true"-->
                <!--                        class="btn text-info">-->
                <!--                  <i class="flaticon-pen d-flex justify-content-center align-items-center "></i></button>-->
                <button data-bs-toggle="tooltip" @click="Details(item.id)"
                        data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Ver detalles del contrato" data-container="body" data-animation="true"
                        class="btn text-secondary">
                  <i class="flaticon-eye d-flex justify-content-center align-items-center "></i></button>
                <!--                <button data-bs-toggle="tooltip"-->
                <!--                        @click="cancelarContrato(item.id)"-->
                <!--                        style="padding: 0.8rem"-->
                <!--                        data-bs-placement="top" v-if="item.estado!=='Cancelado'&& item.estado!=='Cerrado'"-->
                <!--                        title="Cancelar contrato" data-container="body" data-animation="true"-->
                <!--                        class="btn text-danger">-->
                <!--                  <i class="flaticon-close  d-flex justify-content-center align-items-center "></i></button>-->
                <!--                <button data-bs-toggle="tooltip"-->
                <!--                        @click="openEstadoModal(item)"-->
                <!--                        style="padding: 0.8rem"-->
                <!--                        data-bs-placement="top" v-if="item.estado!=='Cancelado'&& item.estado!=='Cerrado'"-->
                <!--                        title="Cerrar contrato" data-container="body" data-animation="true"-->
                <!--                        class="btn text-success">-->
                <!--                  <i class="flaticon-draft  d-flex justify-content-center align-items-center "></i></button>-->
                <button data-bs-toggle="tooltip" @click="generateReportPDF(item.id)"
                        data-bs-placement="top"
                        style="padding: 0.8rem"
                        title="Imprimir o descargar contrato" data-container="body" data-animation="true"
                        class="btn text-info">
                  <i class="flaticon-printer d-flex justify-content-center align-items-center "></i></button>
                <!--                <button data-bs-toggle="tooltip" @click="uploadFiles(item.id)"-->
                <!--                        data-bs-placement="top" v-show="item.estado==='Contratado' || item.estado==='Cerrado'"-->
                <!--                        style="padding: 0.8rem"-->
                <!--                        title="Gestionar imágenes del contrato" data-container="body" data-animation="true"-->
                <!--                        class="btn text-success">-->
                <!--                  <i class="flaticon-image d-flex justify-content-center align-items-center "></i></button>-->
                <!--                <div v-if="item.loadingInvoice" class="spinner-border text-danger icon-loading-custom" role="status">-->
                <!--                  <span class="visually-hidden">Loading...</span>-->
                <!--                </div>-->
                <!--                <span v-else>-->
                <!--                  <button v-if="!item.invoiced" data-bs-toggle="tooltip" @click="openDialogInvoice(item)"-->
                <!--                          data-bs-placement="top"-->
                <!--                          style="padding: 0.8rem"-->
                <!--                          title="Factura" data-container="body" data-animation="true"-->
                <!--                          class="btn text-info">-->
                <!--                    <i class="flaticon-money d-flex justify-content-center align-items-center "></i>-->
                <!--                  </button>-->
                <!--                  <span v-else>-->
                <!--                    <a data-bs-toggle="tooltip" :href="generateUrlDocument(item.invoice.pdfFile)" target="_blank"-->
                <!--                       data-bs-placement="top"-->
                <!--                       style="padding: 0.8rem"-->
                <!--                       title="Descargar pdf factura" data-container="body" data-animation="true"-->
                <!--                       class="btn text-info">-->
                <!--                      <i class="flaticon-document d-flex justify-content-center align-items-center "></i>-->
                <!--                    </a>-->
                <!--                    <a data-bs-toggle="tooltip" :href="generateUrlDocument(item.invoice.xmlFile)" target="_blank"-->
                <!--                       data-bs-placement="top"-->
                <!--                       style="padding: 0.8rem"-->
                <!--                       title="Descargar xml factura" data-container="body" data-animation="true"-->
                <!--                       class="btn text-info">-->
                <!--                      <i class="flaticon-download d-flex justify-content-center align-items-center "></i>-->
                <!--                    </a>-->
                <!--                  </span>-->
                <!--                </span>-->
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="Nuevo text-dark">
    <div v-if="Nuevo_Modal" :class="['modal', { 'show': Nuevo_Modal }]">
      <div class="modal-content bg-white">
        <div class="mb-3 d-flex align-items-center justify-content-between">
          <h4 class="text-start fw-bold">
            Nuevo contrato
          </h4>
          <div class="steps-container">
            <div v-for="step in steps" :key="step" class="step" :class="{ active: currentStep === step }">
              {{ step }}
            </div>
            <div class="step-close">
              <i @click="Close_nuevo" class="flaticon-close opacity-10 modal-icon fs-15"></i>
            </div>
          </div>
        </div>
        <h6 v-if="ventana===0" class="text-start">Datos de recogida y entrega del veh&iacute;culo</h6>
        <h6 v-if="ventana===1" class="text-start">Datos del veh&iacute;culo</h6>
        <h6 v-if="ventana===2" class="text-start">Datos personales</h6>
        <h6 v-if="ventana===3" class="text-start">Datos del pago</h6>
        <div v-show="ventana===0">
          <div class="row mb-3 text-start">
            <div class="col-lg-4 col-md-4 col-sm-12 mb-3 procedencias">
              <label class="form-label ms-1">Procedencia de la reserva<span
                  class="text-danger fw-bold"> *</span></label>
              <select v-model="procedencia" :class="error_procedencia?'is-invalid':'border-success'"
                      class="form-control shadow-none rounded-0 text-black">
                <option disabled :value="0">Seleccione la procedencia de la reserva</option>
                <option v-for="item in procedenciaByName" :key="item.id" :value="item.id">
                  {{ item.nombre }}
                </option>
              </select>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 mb-3 sucursal_salida">
              <label class="form-label ms-1">Sucursal de recogida<span class="text-danger fw-bold"> *</span></label>
              <select v-model="sucursal_recogida" :class="error_sucursal_recogida?'is-invalid':'border-success'"
                      class="form-control shadow-none rounded-0 text-black">
                <option value="0" disabled>Seleccione una sucursal</option>
                <option v-for="item in sucursalesApp" :key="item.id" :value="item.id">{{ item.name }}</option>
              </select>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 mb-3 sucursal_regreso">
              <label class="form-label ms-1">Sucursal de entrega<span class="text-danger fw-bold"> *</span></label>
              <select v-model="sucursal_entrega" :class="error_sucursal_entrega?'is-invalid':'border-success'"
                      class="form-control shadow-none rounded-0 text-black">
                <option value="0" disabled>Seleccione una sucursal</option>
                <option v-for="item in sucursalesApp" :key="item.id" :value="item.id">{{ item.name }}</option>
              </select>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 mb-3 fecha_salida">
              <label class="form-label ms-1">Fecha de salida<span class="text-danger fw-bold"> *</span></label>
              <VueDatePicker v-model="fecha_salida" auto-apply locale="es" :dark="this.$store.state.isDarkMode"
                             :min-date="new Date()"
                             teleport-center :state="!error_fecha_salida" format="dd/MM/yyyy hh:mm aa"
                             time-picker-inline placeholder="Seleccione la fecha"/>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 mb-3 fecha_regreso">
              <label class="form-label ms-1">Fecha de regreso<span class="text-danger fw-bold"> *</span></label>
              <VueDatePicker v-model="fecha_regreso" auto-apply locale="es" :dark="this.$store.state.isDarkMode"
                             :min-date="!fecha_salida?new Date(Date.now()+(24*60*60*1000)):typeof(fecha_salida)=='string'?new Date((new Date(fecha_salida)).getTime()+(24*60*60*1000)):new Date(fecha_salida.getTime()+(24*60*60*1000))"
                             teleport-center :state="!error_fecha_regreso" format="dd/MM/yyyy hh:mm aa"
                             time-picker-inline placeholder="Seleccione la fecha"/>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 mb-3 Dias">
              <label class="form-label ms-1">D&iacute;as<span class="text-danger fw-bold"> *</span></label>
              <input :class="!(dias>0)?'is-invalid':'border-success'" v-model="dias"
                     class="form-control shadow-none rounded-0 text-black" readonly>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 mb-3 categoria">
              <label class="form-label ms-1">Categor&iacute;a<span class="text-danger fw-bold"> *</span></label>
              <select v-model="categoria" :class="error_categoria?'is-invalid':'border-success'"
                      class="form-control shadow-none rounded-0 text-black">
                <option :value="null" disabled>Seleccione una categor&iacute;a</option>
                <option v-for="item in categoriasByName" :key="item.id" :value="item.id">{{ item.name }} $
                  {{ is_higth_season ? item.tarifaAlta : item.tarifaBaja }} MXN
                </option>
              </select>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 mb-3 Precio_Diario">
              <label class="form-label ms-1">Tarifa diaria<span
                  class="text-danger fw-bold"> *</span></label>
              <input v-model="tarifa_diaria"
                     class="form-control shadow-none rounded-0 text-black"
              />
              <div v-if="error_diario" class="invalid-feedback">
                La tarifa diaria no puede ser 0
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 mb-3 protecciones">
              <label class="form-label ms-1">Protecci&oacute;n</label>
              <select v-model="proteccion" :class="proteccion!==null?'border-success':''"
                      class="form-control shadow-none rounded-0 text-black">
                <option :value="null">Protecci&oacute;n b&aacute;sica</option>
                <option v-for="item in proteccionesApp" :key="item" :value="item.id"
                        v-show="item.name.includes(this.category_name)">{{ item.name }} $
                  {{ item.tarifa }} MXN
                </option>
              </select>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-12 mb-3 servicios">
              <label class="form-label ms-1">Servicios extras</label>
              <select class="form-control shadow-none rounded-0 text-black w-321" v-model="servicios"
                      :class="servicios_extras.length > 0?'border-success':''">
                <option value="0" disabled>Seleccione los servicios extras</option>
                <option v-for="item in serviciosExtrasApp" :key="item.id" :value="item.id"
                        :disabled="this.servicios_extras.includes(parseInt(item.id))"
                        v-show="item.name.toLowerCase().includes(this.category_name.toLowerCase()) || item.visibleAll">
                  {{ item.name }} $ {{ item.precio }} MXN / {{ item.diario ? 'Pago diario' : 'Pago único' }}
                </option>
              </select>
              <div class="selected-items">
                <div class="selected-item" v-for="item in serviciosExtrasApp"
                     v-show="servicios_extras.includes(parseInt(item.id))">
                  <span> {{ item.name }} $ {{
                      item.precio
                    }} MXN / {{ item.diario ? 'Pago diario' : 'Pago único' }}</span>
                  <span class="remove-item" @click="Quitar_extra(item.id)">x</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div v-if="error_fecha_salida" class="col-12 text-center p-2"
                 style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                La fecha de salida no puede ser anterior al d&iacute;a de hoy
              </span>
            </div>
            <div v-else v-show="error_vacios" class="col-12 text-center p-2"
                 style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                Error en los campos se&ntilde;alados
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
              <button @click="Close_nuevo" class="btn btn-secondary d-block w-100" type="button">Cancelar</button>
            </div>
            <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
              <button @click="Siguiente_dos" class="btn btn-danger d-block w-100" type="button">Siguiente</button>
            </div>
          </div>
        </div>
        <div v-show="ventana===1">
          <div class="row mb-3 text-start">
            <div class="col-lg-12 col-md-12 col-sm-12 mb-3 marcas">
              <label class="form-label ms-1">Marca<span
                  class="text-danger fw-bold"> *</span></label>
              <select v-model="marca" :class="error_marca?'is-invalid':'border-success'"
                      class="form-control shadow-none rounded-0 text-black">
                <option v-if="reserva!==null" :value="marca_reservada.id">{{ marca_reservada.name }}</option>
                <option disabled :value="0">Seleccione una marca</option>
                <option v-for="item in marcasByCategoria" :key="item.id" :value="item.id">
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 mb-3 vehiculos">
              <label class="form-label ms-1">Veh&iacute;culo<span
                  class="text-danger fw-bold"> *</span></label>
              <select v-model="vehiculo" :class="error_vehiculo?'is-invalid':'border-success'"
                      class="form-control shadow-none rounded-0 text-black">
                <option disabled :value="0">Seleccione un veh&iacute;culo</option>
                <option v-if="reserva!==null" :value="car_reserved.id">{{ car_reserved.no_econ }}</option>
                <option v-for="item in vehiculosByName" :key="item.id" :value="item.id"
                        v-show="item.modelo.marca.id===marca">
                  {{ item.noEconomico }}
                </option>
              </select>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3 placa">
              <label class="form-label ms-1">Placa<span class="text-danger fw-bold"> *</span></label>
              <input disabled class="form-control shadow-none rounded-0 text-black" v-model="no_placa">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3 kilometraje">
              <label class="form-label ms-1">Kilometraje de salida<span class="text-danger fw-bold"> *</span></label>
              <input class="form-control shadow-none rounded-0 text-black" v-model="kilometraje">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3 Combustible">
              <label class="form-label ms-1">Combustible en el tanque (En litros)<span
                  class="text-danger fw-bold"> *</span></label>
              <input class="form-control shadow-none rounded-0 text-black" v-model="gasolina" type="number">
            </div>
          </div>
          <div v-if="conductor_extra" class="row text-start mb-3">
            <h6 class="text-start">Datos del conductor extra</h6>
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 mb-3 nombre">
                  <label class="form-label ms-1">Nombre<span class="text-danger fw-bold"> *</span></label>
                  <input :class="error_nombre?'is-invalid':'border-success'" v-model="nombre_extra"
                         class="form-control shadow-none rounded-0 text-black">
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 mb-3 apellidos">
                  <label class="form-label ms-1">Apellidos<span class="text-danger fw-bold"> *</span></label>
                  <input :class="error_apellidos?'is-invalid':'border-success'" v-model="apellidos_extra"
                         class="form-control shadow-none rounded-0 text-black">
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 mb-3 tipo_licencia">
                  <label class="form-label ms-1">Tipo de licencia<span class="text-danger fw-bold"> *</span></label>
                  <input :class="error_apellidos?'is-invalid':'border-success'" v-model="tipo_licencia_extra"
                         class="form-control shadow-none rounded-0 text-black">
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 mb-3 numero_licencia">
                  <label class="form-label ms-1">N&uacute;mero de licencia<span
                      class="text-danger fw-bold"> *</span></label>
                  <input :class="error_nombre?'is-invalid':'border-success'" v-model="num_licencia_extra"
                         class="form-control shadow-none rounded-0 text-black" maxlength="15">
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 mb-3 vencimiento">
                  <label class="form-label ms-1">Vencimiento de la licencia<span
                      class="text-danger fw-bold"> *</span></label>
                  <VueDatePicker v-model="vencimiento_licencia_extra" auto-apply locale="es"
                                 :dark="this.$store.state.isDarkMode"
                                 teleport-center
                                 :min-date="new Date()"
                                 time-picker-inline placeholder="Seleccione la fecha"
                                 :enable-time-picker="false"/>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-end">
                  <button @click="addExtraDriver" class="btn btn-success">Guardar</button>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="row">
                <table class="table table-responsive">
                  <thead>
                  <tr>
                    <th>Conductor</th>
                    <th>Fecha de vencimiento</th>
                    <th>Acciones</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item in drivers">
                    <td>{{ item.first_name }} {{ item.last_name }}</td>
                    <td>{{ formatearFechaLicense(item.license_expire) }}</td>
                    <td>
                      <button data-bs-toggle="tooltip"
                              style="padding: 0.8rem" @click="deleteDriver(item.license_number)"
                              data-bs-placement="top"
                              title="Quitar conductor extra" data-container="body" data-animation="true"
                              class="btn text-danger">
                        <i class="flaticon-delete  d-flex justify-content-center align-items-center "></i></button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div v-show="error_vacios" class="col-12 text-center p-2"
                 style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                Error en los campos se&ntilde;alados
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
              <button @click="Regresar" class="btn btn-secondary d-block w-100" type="button">Regresar</button>
            </div>
            <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
              <button @click="Siguiente_tres" class="btn btn-danger d-block w-100" type="button">Siguiente</button>
            </div>
          </div>
        </div>
        <div v-show="ventana===2">
          <div class="row mb-3 text-start">
            <div class="col-lg-4 col-md-6 col-sm-12 mb-3 correo">
              <label class="form-label ms-1">Correo<span class="text-danger fw-bold"> *</span></label>
              <input :class="error_email?'is-invalid':'border-success'" v-model="email"
                     class="form-control shadow-none rounded-0 text-black" @input="autocomplete">
              <ul v-if="showSubmenu" class="position-absolute z-index5 bg-white text-dark p-2">
                <li class="dropdown-item cursor-pointer d-block text-dark" v-for="item in autocompleteItems"
                    :key="item.id"
                    @click="selectItem(item)">{{ item.user.email }}
                </li>
              </ul>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 mb-3 nombre">
              <label class="form-label ms-1">Nombre<span class="text-danger fw-bold"> *</span></label>
              <input :class="error_nombre?'is-invalid':'border-success'" v-model="nombre"
                     class="form-control shadow-none rounded-0 text-black">
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 mb-3 apellidos">
              <label class="form-label ms-1">Apellidos<span class="text-danger fw-bold"> *</span></label>
              <input :class="error_apellidos?'is-invalid':'border-success'" v-model="apellidos"
                     class="form-control shadow-none rounded-0 text-black">
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 mb-3 telefono">
              <label class="form-label ms-1">Tel&eacute;fono<span class="text-danger fw-bold"> *</span></label>
              <MazPhoneNumberInput
                  v-model="telefono"
                  v-model:country-code="codigo"
                  no-radius
                  class="w-100"
                  :preferred-countries="['MX', 'US', 'CU']"
                  :error="error_telefono"
                  :translations="{
                    countrySelector: {
                      placeholder: 'Código de país',
                      error: 'Seleccione un país',
                      searchPlaceholder: 'Buscar un país',
                    },
                    phoneInput: {
                      placeholder: 'Número de teléfono',
                      example: 'Ejemplo:',
                    },
                  }"
              />
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 mb-3 licencia">
              <label class="form-label ms-1">Licencia<span class="text-danger fw-bold"> *</span></label>
              <input :class="error_licencia?'is-invalid':'border-success'" v-model="licencia"
                     class="form-control shadow-none rounded-0 text-black" maxlength="15">
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 mb-3 vencimiento">
              <label class="form-label ms-1">Vencimiento<span class="text-danger fw-bold"> *</span></label>
              <VueDatePicker v-model="vencimiento" auto-apply locale="es"
                             :dark="this.$store.state.isDarkMode"
                             teleport-center
                             :min-date="new Date()"
                             time-picker-inline placeholder="Seleccione la fecha"
                             :enable-time-picker="false"/>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 mb-3 ciudad">
              <label class="form-label ms-1">Ciudad<span class="text-danger fw-bold"> *</span></label>
              <input :class="error_ciudad?'is-invalid':'border-success'" v-model="ciudad"
                     class="form-control shadow-none rounded-0 text-black">
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 mb-3 estado">
              <label class="form-label ms-1">Estado<span class="text-danger fw-bold"> *</span></label>
              <input :class="error_estado?'is-invalid':'border-success'" v-model="state"
                     class="form-control shadow-none rounded-0 text-black">
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 mb-3 pais">
              <label class="form-label ms-1">Pa&iacute;s<span class="text-danger fw-bold"> *</span></label>
              <input :class="error_pais?'is-invalid':'border-success'" v-model="pais"
                     class="form-control shadow-none rounded-0 text-black">
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 mb-3 direccion">
              <label class="form-label ms-1">Direcci&oacute;n<span class="text-danger fw-bold"> *</span></label>
              <textarea :class="error_direccion?'is-invalid':'border-success'" v-model="direccion"
                        class="form-control shadow-none rounded-0 text-black"></textarea>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 mb-3 comentarios">
              <label class="form-label ms-1">Comentarios</label>
              <textarea class="form-control shadow-none rounded-0 text-black" v-model="comentarios"></textarea>
            </div>
          </div>
          <div class="row mb-3">
            <div v-show="error_vacios" class="col-12 text-center p-2"
                 style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                Error en los campos se&ntilde;alados
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
              <button @click="Regresar" class="btn btn-secondary d-block w-100" type="button">Regresar</button>
            </div>
            <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
              <button @click="Siguiente_cuatro" class="btn btn-danger d-block w-100" type="button">Siguiente</button>
            </div>
          </div>
        </div>
        <div v-show="ventana===3">
          <div class="row mb-3 text-start">
            <div class="col-lg-4 col-md-6 col-sm-12 mb-3 metodo_pago">
              <div class="form-check">
                <input v-model="selectedPayments.efectivo" class="form-check-input shadow-none" type="checkbox" value=""
                       id="flexCheckDefault">
                <label class="form-check-label fw-medium position-relative top-1" for="flexCheckDefault">
                  Pago en efectivo
                </label>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 mb-3 metodo_pago">
              <div class="form-check">
                <input v-model="selectedPayments.tarjeta" class="form-check-input shadow-none" type="checkbox" value=""
                       id="flexCheckDefault">
                <label class="form-check-label fw-medium position-relative top-1" for="flexCheckDefault">
                  Pago por tarjeta
                </label>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 mb-3 metodo_pago">
              <div class="form-check">
                <input v-model="selectedPayments.transferencia" class="form-check-input shadow-none" type="checkbox"
                       value=""
                       id="flexCheckDefault">
                <label class="form-check-label fw-medium position-relative top-1" for="flexCheckDefault">
                  Pago por transferencia
                </label>
              </div>
            </div>
          </div>
          <div class="row mb-3 efectivo text-start">
            <div class="col-lg-4 col-md-6 col-sm-12 mb-3 monto">
              <label class="form-label ms-1">Importe pagado en efectivo<span
                  class="text-danger fw-bold"> *</span></label>
              <input :disabled="!selectedPayments.efectivo" v-model="deposit_cash"
                     class="form-control shadow-none rounded-0 text-black">
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 mb-3 monto">
              <label class="form-label ms-1">Importe pagado por tarjeta<span
                  class="text-danger fw-bold"> *</span></label>
              <input :disabled="!selectedPayments.tarjeta" v-model="deposit_tarjet"
                     :class="error_garantia?'is-invalid':'border-success'"
                     class="form-control shadow-none rounded-0 text-black mb-1">
              <label class="form-label ms-1">N&uacute;mero de la tarjeta<span
                  class="text-danger fw-bold"> *</span></label>
              <input :disabled="!selectedPayments.tarjeta" v-model="tarjeta_numero"
                     :class="error_tarjeta_numero?'is-invalid':'border-success'"
                     class="form-control shadow-none rounded-0 text-black mb-1" maxlength="16">
              <label class="form-label ms-1">Vencimiento de la tarjeta<span
                  class="text-danger fw-bold"> *</span></label>
              <VueDatePicker :disabled="!selectedPayments.tarjeta" v-model="fecha_tarjeta" auto-apply locale="es"
                             :dark="this.$store.state.isDarkMode"
                             teleport-center :state="!error_tarjeta_vencimiento" month-picker
                             :min-date="new Date()"
                             time-picker-inline placeholder="Seleccione la fecha"
                             :enable-time-picker="false"/>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 mb-3 tarjeta_numero">
              <label class="form-label ms-1">Importe pagado por transferencia<span
                  class="text-danger fw-bold"> *</span></label>
              <input :disabled="!selectedPayments.transferencia" v-model="deposit_transfer"
                     :class="error_garantia?'is-invalid':'border-success'"
                     class="form-control shadow-none rounded-0 text-black mb-1">
              <label class="form-label ms-1">Referencia<span class="text-danger fw-bold"> *</span></label>
              <input :disabled="!selectedPayments.transferencia"
                     :class="error_transferencia_referencia?'is-invalid':'border-success'"
                     v-model="transferencia_referencia"
                     class="form-control shadow-none rounded-0 text-black mb-1">
              <label class="form-label ms-1">Banco<span class="text-danger fw-bold"> *</span></label>
              <input :disabled="!selectedPayments.transferencia"
                     :class="error_transferencia_banco?'is-invalid':'border-success'"
                     v-model="transferencia_banco"
                     class="form-control shadow-none rounded-0 text-black mb-1">
              <label class="form-label ms-1">Concepto<span class="text-danger fw-bold"> *</span></label>
              <textarea :disabled="!selectedPayments.transferencia"
                        class="form-control shadow-none rounded-0 text-black"
                        :class="error_transferencia_concepto?'is-invalid':'border-success'"
                        v-model="transferencia_concepto"></textarea>
            </div>
          </div>
          <div class="row mb-3 text-start">
            <h6>D&eacute;posito de garant&iacute;a</h6>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3 garantia">
              <label class="form-label ms-1">Importe<span
                  class="text-danger fw-bold"> *</span></label>
              <input v-model="garantia" :class="error_garantia?'is-invalid':'border-success'"
                     class="form-control shadow-none rounded-0 text-black">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3 tarjeta_numero">
              <label class="form-label ms-1">N&uacute;mero de la tarjeta<span
                  class="text-danger fw-bold"> *</span></label>
              <input v-model="depositTarjetNumber" :class="errordepositTarjetNumber?'is-invalid':'border-success'"
                     class="form-control shadow-none rounded-0 text-black" maxlength="16">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3 tarjeta_autorizacion">
              <label class="form-label ms-1">Autorizaci&oacute;n<span
                  class="text-danger fw-bold"> *</span></label>
              <input v-model="depositTarjetAuth" :class="errordepositTarjetAuth?'is-invalid':'border-success'"
                     class="form-control shadow-none rounded-0 text-black" maxlength="16">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3 tarjeta_vencimiento">
              <label class="form-label ms-1">Vencimiento de la tarjeta<span
                  class="text-danger fw-bold"> *</span></label>
              <VueDatePicker v-model="depositTarjetExp" auto-apply locale="es" :dark="this.$store.state.isDarkMode"
                             teleport-center :state="!errordepositTarjetExp" month-picker
                             :min-date="new Date()"
                             time-picker-inline placeholder="Seleccione la fecha"
                             :enable-time-picker="false"/>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-between">
              <span class="fw-bold">Tarifa diaria</span>
              <span>{{ dias }} X $ {{ numeroFormateado(tarifa_diaria) }} MXN</span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-between">
              <span class="fw-bold">Subtotal</span>
              <span>$ {{ numeroFormateado(sub_total) }} MXN</span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-between">
              <span class="fw-bold">Tarifa de servicios extras</span>
              <span>$ {{ numeroFormateado(tarifa_servicios) }} MXN</span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-between">
              <span class="fw-bold">Tarifa iva</span>
              <span>$ {{ numeroFormateado(tarifa_iva) }} MXN</span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-between">
              <span class="fw-bold">Tarifa de protecci&oacute;n</span>
              <span>$ {{ numeroFormateado(tarifa_proteccion) }} MXN</span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-between">
              <span class="fw-bold">Total</span>
              <span>$ {{ numeroFormateado(total) }} MXN</span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-between">
              <span class="fw-bold">Tarifa de drop off</span>
              <span>$ {{ numeroFormateado(tarifa_dropoff) }} MXN</span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-between">
              <span class="fw-bold text-success">Monto pagado en reserva</span>
              <span class=" text-success">$ {{ numeroFormateado(monto_pagado) }} MXN</span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-between">
              <span class="fw-bold">Monto de promoci&oacute;n</span>
              <span>$ {{
                  reserva ? reserva.promocion ? numeroFormateado(reserva.totalPromocion) : "0.00" : "0.00"
                }} MXN</span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-between">
              <span class="fw-bold text-danger">Pago pendiente</span>
              <span class="text-danger">$ {{ numeroFormateado(pago_pendiente) }} MXN</span>
            </div>
          </div>
          <div class="row mb-3">
            <div v-show="error_vacios" class="col-12 text-center p-2"
                 style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                Error en los campos se&ntilde;alados
              </span>
            </div>
            <div v-show="error_monto_total" class="col-12 text-center p-2"
                 style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                La suma de los montos por tipo de pagos debe corresponder con el total del contrato
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
              <button @click="Regresar" :disabled="saveChanges" class="btn btn-secondary d-block w-100" type="button">
                Regresar
              </button>
            </div>
            <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
              <button @click="Crear" class="btn btn-danger d-block w-100" :disabled="saveChanges">
                <div v-if="saveChanges" class="spinner-border text-dark" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <span v-else>Generar contrato</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="Actualizar text-dark">
    <div v-if="Update_Modal" :class="['modal', { 'show': Update_Modal }]">
      <div class="modal-content bg-white">
        <div class="mb-3 d-flex align-items-center justify-content-between">
          <h4 class="text-start fw-bold">
            Actualizar contrato
          </h4>
          <div class="steps-container">
            <div v-for="step in steps" :key="step" class="step" :class="{ active: currentStep === step }">
              {{ step }}
            </div>
            <div class="step-close">
              <i @click="Close_nuevo" class="flaticon-close opacity-10 modal-icon fs-15"></i>
            </div>
          </div>
        </div>
        <h6 v-if="ventana===0" class="text-start">Datos de recogida y entrega del veh&iacute;culo</h6>
        <h6 v-if="ventana===1" class="text-start">Datos del veh&iacute;culo</h6>
        <h6 v-if="ventana===2" class="text-start">Datos personales</h6>
        <h6 v-if="ventana===3" class="text-start">Datos del pago</h6>
        <div v-show="ventana===0">
          <div class="row mb-3 text-start">
            <div class="col-lg-4 col-md-4 col-sm-12 mb-3 procedencias">
              <label class="form-label ms-1">Procedencia de la reserva<span
                  class="text-danger fw-bold"> *</span></label>
              <select v-model="procedencia" :class="error_procedencia?'is-invalid':'border-success'"
                      class="form-control shadow-none rounded-0 text-black">
                <option disabled :value="0">Seleccione la procedencia de la reserva</option>
                <option v-for="item in procedenciaByName" :key="item.id" :value="item.id">
                  {{ item.nombre }}
                </option>
              </select>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 mb-3 sucursal_salida">
              <label class="form-label ms-1">Sucursal de recogida<span class="text-danger fw-bold"> *</span></label>
              <select v-model="sucursal_recogida" :class="error_sucursal_recogida?'is-invalid':'border-success'"
                      class="form-control shadow-none rounded-0 text-black">
                <option value="0" disabled>Seleccione una sucursal</option>
                <option v-for="item in sucursalesApp" :key="item.id" :value="item.id">{{ item.name }}</option>
              </select>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 mb-3 sucursal_regreso">
              <label class="form-label ms-1">Sucursal de entrega<span class="text-danger fw-bold"> *</span></label>
              <select v-model="sucursal_entrega" :class="error_sucursal_entrega?'is-invalid':'border-success'"
                      class="form-control shadow-none rounded-0 text-black">
                <option value="0" disabled>Seleccione una sucursal</option>
                <option v-for="item in sucursalesApp" :key="item.id" :value="item.id">{{ item.name }}</option>
              </select>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 mb-3 fecha_salida">
              <label class="form-label ms-1">Fecha de salida<span class="text-danger fw-bold"> *</span></label>
              <VueDatePicker v-model="fecha_salida" auto-apply locale="es" :dark="this.$store.state.isDarkMode"
                             :min-date="new Date()"
                             teleport-center :state="!error_fecha_salida" format="dd/MM/yyyy hh:mm aa"
                             time-picker-inline placeholder="Seleccione la fecha"/>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 mb-3 fecha_regreso">
              <label class="form-label ms-1">Fecha de regreso<span class="text-danger fw-bold"> *</span></label>
              <VueDatePicker v-model="fecha_regreso" auto-apply locale="es" :dark="this.$store.state.isDarkMode"
                             :min-date="!fecha_salida?new Date(Date.now()+(24*60*60*1000)):typeof(fecha_salida)=='string'?new Date((new Date(fecha_salida)).getTime()+(24*60*60*1000)):new Date(fecha_salida.getTime()+(24*60*60*1000))"
                             teleport-center :state="!error_fecha_regreso" format="dd/MM/yyyy hh:mm aa"
                             time-picker-inline placeholder="Seleccione la fecha"/>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 mb-3 Dias">
              <label class="form-label ms-1">D&iacute;as<span class="text-danger fw-bold"> *</span></label>
              <input :class="!(dias>0)?'is-invalid':'border-success'" v-model="dias"
                     class="form-control shadow-none rounded-0 text-black" readonly>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 mb-3 categoria">
              <label class="form-label ms-1">Categor&iacute;a<span class="text-danger fw-bold"> *</span></label>
              <select v-model="categoria" :class="error_categoria?'is-invalid':'border-success'"
                      class="form-control shadow-none rounded-0 text-black">
                <option :value="null" disabled>Seleccione una categor&iacute;a</option>
                <option v-for="item in categoriasByName" :key="item.id" :value="item.id">{{ item.name }} $
                  {{ is_higth_season ? item.tarifaAlta : item.tarifaBaja }} MXN
                </option>
              </select>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 mb-3 Precio_Diario">
              <label class="form-label ms-1">Tarifa diaria<span
                  class="text-danger fw-bold"> *</span></label>
              <input v-model="tarifa_diaria"
                     class="form-control shadow-none rounded-0 text-black"
              />
              <div v-if="error_diario" class="invalid-feedback">
                La tarifa diaria no puede ser 0
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 mb-3 protecciones">
              <label class="form-label ms-1">Protecci&oacute;n</label>
              <select v-model="proteccion" :class="proteccion!==null?'border-success':''"
                      class="form-control shadow-none rounded-0 text-black">
                <option :value="null">Protecci&oacute;n b&aacute;sica</option>
                <option v-for="item in proteccionesApp" :key="item" :value="item.id"
                        v-show="item.name.includes(this.category_name)">{{ item.name }} $
                  {{ item.tarifa }} MXN
                </option>
              </select>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-12 mb-3 servicios">
              <label class="form-label ms-1">Servicios extras</label>
              <select class="form-control shadow-none rounded-0 text-black w-321" v-model="servicios"
                      :class="servicios_extras.length > 0?'border-success':''">
                <option value="0" disabled>Seleccione los servicios extras</option>
                <option v-for="item in serviciosExtrasApp" :key="item.id" :value="item.id"
                        :disabled="this.servicios_extras.includes(parseInt(item.id)) || item.visibleAll"
                        v-show="item.name.toLowerCase().includes(this.category_name.toLowerCase())">
                  {{ item.name }} $ {{ item.precio }} MXN / {{ item.diario ? 'Pago diario' : 'Pago único' }}
                </option>
              </select>
              <div class="selected-items">
                <div class="selected-item" v-for="item in serviciosExtrasApp"
                     v-show="servicios_extras.includes(parseInt(item.id))">
                  <span> {{ item.name }} $ {{
                      item.precio
                    }} MXN / {{ item.diario ? 'Pago diario' : 'Pago único' }}</span>
                  <span class="remove-item" @click="Quitar_extra(item.id)">x</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div v-if="error_fecha_salida" class="col-12 text-center p-2"
                 style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                La fecha de salida no puede ser anterior al d&iacute;a de hoy
              </span>
            </div>
            <div v-else v-show="error_vacios" class="col-12 text-center p-2"
                 style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                Error en los campos se&ntilde;alados
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
              <button @click="Close_nuevo" class="btn btn-secondary d-block w-100" type="button">Cancelar</button>
            </div>
            <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
              <button @click="Siguiente_dos" class="btn btn-danger d-block w-100" type="button">Siguiente</button>
            </div>
          </div>
        </div>
        <div v-show="ventana===1">
          <div class="row mb-3 text-start">
            <div class="col-lg-12 col-md-12 col-sm-12 mb-3 marcas">
              <label class="form-label ms-1">Marca<span
                  class="text-danger fw-bold"> *</span></label>
              <select v-model="marca" :class="error_marca?'is-invalid':'border-success'"
                      class="form-control shadow-none rounded-0 text-black">
                <option v-if="reserva!==null" :value="marca_reservada.id">{{ marca_reservada.name }}</option>
                <option disabled :value="0">Seleccione una marca</option>
                <option v-for="item in marcasByCategoria" :key="item.id" :value="item.id">
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 mb-3 vehiculos">
              <label class="form-label ms-1">Veh&iacute;culo<span
                  class="text-danger fw-bold"> *</span></label>
              <select v-model="vehiculo" :class="error_vehiculo?'is-invalid':'border-success'"
                      class="form-control shadow-none rounded-0 text-black">
                <option disabled :value="0">Seleccione un veh&iacute;culo</option>
                <option v-if="reserva!==null" :value="car_reserved.id">{{ car_reserved.no_econ }}</option>
                <option v-for="item in vehiculosByName" :key="item.id" :value="item.id"
                        v-show="item.modelo.marca.id===marca">
                  {{ item.noEconomico }}
                </option>
              </select>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3 placa">
              <label class="form-label ms-1">Placa<span class="text-danger fw-bold"> *</span></label>
              <input disabled class="form-control shadow-none rounded-0 text-black" v-model="no_placa">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3 kilometraje">
              <label class="form-label ms-1">Kilometraje de salida<span class="text-danger fw-bold"> *</span></label>
              <input class="form-control shadow-none rounded-0 text-black" v-model="kilometraje">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3 Combustible">
              <label class="form-label ms-1">Combustible en el tanque (En litros)<span
                  class="text-danger fw-bold"> *</span></label>
              <input class="form-control shadow-none rounded-0 text-black" v-model="gasolina" type="number">
            </div>
          </div>
          <div v-if="conductor_extra" class="row text-start mb-3">
            <h6 class="text-start">Datos del conductor extra</h6>
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 mb-3 nombre">
                  <label class="form-label ms-1">Nombre<span class="text-danger fw-bold"> *</span></label>
                  <input :class="error_nombre?'is-invalid':'border-success'" v-model="nombre_extra"
                         class="form-control shadow-none rounded-0 text-black">
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 mb-3 apellidos">
                  <label class="form-label ms-1">Apellidos<span class="text-danger fw-bold"> *</span></label>
                  <input :class="error_apellidos?'is-invalid':'border-success'" v-model="apellidos_extra"
                         class="form-control shadow-none rounded-0 text-black">
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 mb-3 tipo_licencia">
                  <label class="form-label ms-1">Tipo de licencia<span class="text-danger fw-bold"> *</span></label>
                  <input :class="error_apellidos?'is-invalid':'border-success'" v-model="tipo_licencia_extra"
                         class="form-control shadow-none rounded-0 text-black">
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 mb-3 numero_licencia">
                  <label class="form-label ms-1">N&uacute;mero de licencia<span
                      class="text-danger fw-bold"> *</span></label>
                  <input :class="error_nombre?'is-invalid':'border-success'" v-model="num_licencia_extra"
                         class="form-control shadow-none rounded-0 text-black" maxlength="15">
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 mb-3 vencimiento">
                  <label class="form-label ms-1">Vencimiento de la licencia<span
                      class="text-danger fw-bold"> *</span></label>
                  <VueDatePicker v-model="vencimiento_licencia_extra" auto-apply locale="es"
                                 :dark="this.$store.state.isDarkMode"
                                 teleport-center
                                 :min-date="new Date()"
                                 time-picker-inline placeholder="Seleccione la fecha"
                                 :enable-time-picker="false"/>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-end">
                  <button @click="createExtraDriver" class="btn btn-success">Guardar</button>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="row">
                <table class="table table-responsive">
                  <thead>
                  <tr>
                    <th>Conductor</th>
                    <th>Fecha de vencimiento</th>
                    <th>Acciones</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item in drivers_update">
                    <td>{{ item.extraDriver.nombre }} {{ item.extraDriver.apellidos }}</td>
                    <td>{{ formatearFechaLicense(item.extraDriver.license_expire) }}</td>
                    <td>
                      <button data-bs-toggle="tooltip"
                              style="padding: 0.8rem" @click="deleteExtraDriver(item.id)"
                              data-bs-placement="top"
                              title="Quitar conductor extra" data-container="body" data-animation="true"
                              class="btn text-danger">
                        <i class="flaticon-delete  d-flex justify-content-center align-items-center "></i></button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div v-show="error_vacios" class="col-12 text-center p-2"
                 style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                Error en los campos se&ntilde;alados
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
              <button @click="Regresar" class="btn btn-secondary d-block w-100" type="button">Regresar</button>
            </div>
            <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
              <button @click="Siguiente_tres" class="btn btn-danger d-block w-100" type="button">Siguiente</button>
            </div>
          </div>
        </div>
        <div v-show="ventana===2">
          <div class="row mb-3 text-start">
            <div class="col-lg-12 col-md-12 col-sm-12 mb-3 correo">
              <label class="form-label ms-1">Correo<span class="text-danger fw-bold"> *</span></label>
              <input :class="error_email?'is-invalid':'border-success'" v-model="email"
                     class="form-control shadow-none rounded-0 text-black" @input="autocomplete">
              <ul v-if="showSubmenu" class="position-absolute z-index2 bg-white text-dark p-2">
                <li class="dropdown-item cursor-pointer d-block text-dark" v-for="item in autocompleteItems"
                    :key="item.id"
                    @click="selectItem(item)">{{ item.user.email }}
                </li>
              </ul>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3 nombre">
              <label class="form-label ms-1">Nombre<span class="text-danger fw-bold"> *</span></label>
              <input :class="error_nombre?'is-invalid':'border-success'" v-model="nombre"
                     class="form-control shadow-none rounded-0 text-black">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3 apellidos">
              <label class="form-label ms-1">Apellidos<span class="text-danger fw-bold"> *</span></label>
              <input :class="error_apellidos?'is-invalid':'border-success'" v-model="apellidos"
                     class="form-control shadow-none rounded-0 text-black">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3 telefono">
              <label class="form-label ms-1">Tel&eacute;fono<span class="text-danger fw-bold"> *</span></label>
              <MazPhoneNumberInput
                  v-model="telefono"
                  v-model:country-code="codigo"
                  no-radius
                  class="w-100"
                  :preferred-countries="['MX', 'US', 'CU']"
                  :error="error_telefono"
                  :translations="{
                    countrySelector: {
                      placeholder: 'Código de país',
                      error: 'Seleccione un país',
                      searchPlaceholder: 'Buscar un país',
                    },
                    phoneInput: {
                      placeholder: 'Número de teléfono',
                      example: 'Ejemplo:',
                    },
                  }"
              />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3 licencia">
              <label class="form-label ms-1">Licencia<span class="text-danger fw-bold"> *</span></label>
              <input :class="error_licencia?'is-invalid':'border-success'" v-model="licencia"
                     class="form-control shadow-none rounded-0 text-black" maxlength="15">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3 vencimiento">
              <label class="form-label ms-1">Vencimiento<span class="text-danger fw-bold"> *</span></label>
              <VueDatePicker v-model="vencimiento" auto-apply locale="es"
                             :dark="this.$store.state.isDarkMode"
                             teleport-center
                             :min-date="new Date()"
                             time-picker-inline placeholder="Seleccione la fecha"
                             :enable-time-picker="false"/>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3 ciudad">
              <label class="form-label ms-1">Ciudad<span class="text-danger fw-bold"> *</span></label>
              <input :class="error_ciudad?'is-invalid':'border-success'" v-model="ciudad"
                     class="form-control shadow-none rounded-0 text-black">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3 estado">
              <label class="form-label ms-1">Estado<span class="text-danger fw-bold"> *</span></label>
              <input :class="error_estado?'is-invalid':'border-success'" v-model="state"
                     class="form-control shadow-none rounded-0 text-black">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3 pais">
              <label class="form-label ms-1">Pa&iacute;s<span class="text-danger fw-bold"> *</span></label>
              <input :class="error_pais?'is-invalid':'border-success'" v-model="pais"
                     class="form-control shadow-none rounded-0 text-black">
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 mb-3 direccion">
              <label class="form-label ms-1">Direcci&oacute;n<span class="text-danger fw-bold"> *</span></label>
              <input :class="error_direccion?'is-invalid':'border-success'" v-model="direccion"
                     class="form-control shadow-none rounded-0 text-black">
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 mb-3 comentarios">
              <label class="form-label ms-1">Comentarios</label>
              <textarea class="form-control shadow-none rounded-0 text-black" v-model="comentarios"></textarea>
            </div>
          </div>
          <div class="row mb-3">
            <div v-show="error_vacios" class="col-12 text-center p-2"
                 style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                Error en los campos se&ntilde;alados
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
              <button @click="Regresar" class="btn btn-secondary d-block w-100" type="button">Regresar</button>
            </div>
            <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
              <button @click="Siguiente_cuatro" class="btn btn-danger d-block w-100" type="button">Siguiente</button>
            </div>
          </div>
        </div>
        <div v-show="ventana===3">
          <div class="row mb-3 text-start">
            <div class="col-lg-4 col-md-6 col-sm-12 mb-3 metodo_pago">
              <div class="form-check">
                <input v-model="selectedPayments.efectivo" class="form-check-input shadow-none" type="checkbox" value=""
                       id="flexCheckDefault">
                <label class="form-check-label fw-medium position-relative top-1" for="flexCheckDefault">
                  Pago en efectivo
                </label>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 mb-3 metodo_pago">
              <div class="form-check">
                <input v-model="selectedPayments.tarjeta" class="form-check-input shadow-none" type="checkbox" value=""
                       id="flexCheckDefault">
                <label class="form-check-label fw-medium position-relative top-1" for="flexCheckDefault">
                  Pago por tarjeta
                </label>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 mb-3 metodo_pago">
              <div class="form-check">
                <input v-model="selectedPayments.transferencia" class="form-check-input shadow-none" type="checkbox"
                       value=""
                       id="flexCheckDefault">
                <label class="form-check-label fw-medium position-relative top-1" for="flexCheckDefault">
                  Pago por transferencia
                </label>
              </div>
            </div>
          </div>
          <div class="row mb-3 efectivo text-start">
            <div class="col-lg-4 col-md-6 col-sm-12 mb-3 monto">
              <label class="form-label ms-1">Importe pagado en efectivo<span
                  class="text-danger fw-bold"> *</span></label>
              <input :disabled="!selectedPayments.efectivo" v-model="deposit_cash"
                     class="form-control shadow-none rounded-0 text-black">
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 mb-3 monto">
              <label class="form-label ms-1">Importe pagado por tarjeta<span
                  class="text-danger fw-bold"> *</span></label>
              <input :disabled="!selectedPayments.tarjeta" v-model="deposit_tarjet"
                     :class="error_garantia?'is-invalid':'border-success'"
                     class="form-control shadow-none rounded-0 text-black mb-1">
              <label class="form-label ms-1">N&uacute;mero de la tarjeta<span
                  class="text-danger fw-bold"> *</span></label>
              <input :disabled="!selectedPayments.tarjeta" v-model="tarjeta_numero"
                     :class="error_tarjeta_numero?'is-invalid':'border-success'"
                     class="form-control shadow-none rounded-0 text-black mb-1" maxlength="16">
              <label class="form-label ms-1">Vencimiento de la tarjeta<span
                  class="text-danger fw-bold"> *</span></label>
              <VueDatePicker :disabled="!selectedPayments.tarjeta" v-model="fecha_tarjeta" auto-apply locale="es"
                             :dark="this.$store.state.isDarkMode"
                             teleport-center :state="!error_tarjeta_vencimiento" month-picker
                             :min-date="new Date()"
                             time-picker-inline placeholder="Seleccione la fecha"
                             :enable-time-picker="false"/>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 mb-3 tarjeta_numero">
              <label class="form-label ms-1">Importe pagado por transferencia<span
                  class="text-danger fw-bold"> *</span></label>
              <input :disabled="!selectedPayments.transferencia" v-model="deposit_transfer"
                     :class="error_garantia?'is-invalid':'border-success'"
                     class="form-control shadow-none rounded-0 text-black mb-1">
              <label class="form-label ms-1">Referencia<span class="text-danger fw-bold"> *</span></label>
              <input :disabled="!selectedPayments.transferencia"
                     :class="error_transferencia_referencia?'is-invalid':'border-success'"
                     v-model="transferencia_referencia"
                     class="form-control shadow-none rounded-0 text-black mb-1">
              <label class="form-label ms-1">Banco<span class="text-danger fw-bold"> *</span></label>
              <input :disabled="!selectedPayments.transferencia"
                     :class="error_transferencia_banco?'is-invalid':'border-success'"
                     v-model="transferencia_banco"
                     class="form-control shadow-none rounded-0 text-black mb-1">
              <label class="form-label ms-1">Concepto<span class="text-danger fw-bold"> *</span></label>
              <textarea :disabled="!selectedPayments.transferencia"
                        class="form-control shadow-none rounded-0 text-black"
                        :class="error_transferencia_concepto?'is-invalid':'border-success'"
                        v-model="transferencia_concepto"></textarea>
            </div>
          </div>
          <div class="row mb-3 text-start">
            <h6>D&eacute;posito de garant&iacute;a</h6>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3 garantia">
              <label class="form-label ms-1">Importe<span
                  class="text-danger fw-bold"> *</span></label>
              <input v-model="garantia" :class="error_garantia?'is-invalid':'border-success'"
                     class="form-control shadow-none rounded-0 text-black">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3 tarjeta_numero">
              <label class="form-label ms-1">N&uacute;mero de la tarjeta<span
                  class="text-danger fw-bold"> *</span></label>
              <input v-model="depositTarjetNumber" :class="errordepositTarjetNumber?'is-invalid':'border-success'"
                     class="form-control shadow-none rounded-0 text-black" maxlength="16">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3 tarjeta_autorizacion">
              <label class="form-label ms-1">Autorizaci&oacute;n<span
                  class="text-danger fw-bold"> *</span></label>
              <input v-model="depositTarjetAuth" :class="errordepositTarjetAuth?'is-invalid':'border-success'"
                     class="form-control shadow-none rounded-0 text-black" maxlength="16">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 mb-3 tarjeta_vencimiento">
              <label class="form-label ms-1">Vencimiento de la tarjeta<span
                  class="text-danger fw-bold"> *</span></label>
              <VueDatePicker v-model="depositTarjetExp" auto-apply locale="es" :dark="this.$store.state.isDarkMode"
                             teleport-center :state="!errordepositTarjetExp" month-picker
                             :min-date="new Date()"
                             time-picker-inline placeholder="Seleccione la fecha"
                             :enable-time-picker="false"/>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-between">
              <span class="fw-bold">Tarifa diaria</span>
              <span>{{ dias }} X $ {{ numeroFormateado(tarifa_diaria) }} MXN</span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-between">
              <span class="fw-bold">Subtotal</span>
              <span>$ {{ numeroFormateado(sub_total) }} MXN</span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-between">
              <span class="fw-bold">Tarifa de servicios extras</span>
              <span>$ {{ numeroFormateado(tarifa_servicios) }} MXN</span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-between">
              <span class="fw-bold">Tarifa iva</span>
              <span>$ {{ numeroFormateado(tarifa_iva) }} MXN</span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-between">
              <span class="fw-bold">Tarifa de protecci&oacute;n</span>
              <span>$ {{ numeroFormateado(tarifa_proteccion) }} MXN</span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-between">
              <span class="fw-bold">Total</span>
              <span>$ {{ numeroFormateado(total) }} MXN</span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-between">
              <span class="fw-bold">Tarifa de drop off</span>
              <span>$ {{ numeroFormateado(tarifa_dropoff) }} MXN</span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-between">
              <span class="fw-bold text-success">Monto pagado en reserva</span>
              <span class=" text-success">$ {{ numeroFormateado(monto_pagado) }} MXN</span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-between">
              <span class="fw-bold">Monto de promoci&oacute;n</span>
              <span>$ {{
                  reserva ? reserva.promocion ? numeroFormateado(reserva.totalPromocion) : "0.00" : "0.00"
                }} MXN</span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-between">
              <span class="fw-bold text-danger">Pago pendiente</span>
              <span class="text-danger">$ {{ numeroFormateado(pago_pendiente) }} MXN</span>
            </div>
          </div>
          <div class="row mb-3">
            <div v-show="error_vacios" class="col-12 text-center p-2"
                 style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                Error en los campos se&ntilde;alados
              </span>
            </div>
            <div v-show="error_monto_total" class="col-12 text-center p-2"
                 style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                La suma de los montos por tipo de pagos debe corresponder con el total del contrato
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
              <button @click="Regresar" :disabled="saveChanges" class="btn btn-secondary d-block w-100" type="button">
                Regresar
              </button>
            </div>
            <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
              <button @click="Editar" class="btn btn-danger d-block w-100" :disabled="saveChanges">
                <div v-if="saveChanges" class="spinner-border text-dark" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <span v-else>Generar contrato</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="Details text-dark">
    <div v-if="Details_Modal" :class="['modal', { 'show': Details_Modal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Detalles del contrato
            <i @click="close_Details" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div v-show="pagina===0" class="row mb-1 text-start">
          <h5>Datos del cliente</h5>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <h6>Nombre:</h6>
            <p>{{ item.cliente.nombre }}</p>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <h6>Apellidos:</h6>
            <p>{{ item.cliente.apellidos }}</p>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <h6>Correo:</h6>
            <p>{{ item.cliente.user.email }}</p>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <h6>Tel&eacute;fono:</h6>
            <p>{{ item.cliente.contactoSet.edges[0].node.telefono }}</p>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <h6>Fecha de salida:</h6>
            <p>{{ formatearFecha(item.fechaSalida) }}</p>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <h6>Fecha de regreso:</h6>
            <p>{{ formatearFecha(item.fechaRegreso) }}</p>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <h6>Sucursal de recogida:</h6>
            <p>{{ item.contratoSucursalRecogida.name }}</p>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <h6>Sucursal de entrega:</h6>
            <p>{{ item.contratoSucursalEntrega.name }}</p>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <h6>Categor&iacute;a:</h6>
            <p>{{ item.vehiculo.categoria.name }}</p>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <h6>Comentario:</h6>
            <p>{{ item.comentario }}</p>
          </div>
        </div>
        <div v-show="pagina===1" class="row mb-1 text-start">
          <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12" v-if="damages.length>0">
              <h5 class="mb-3">Daños al vehículo</h5>
              <table class="table table-responsive">
                <thead>
                <tr>
                  <th style="padding:2px">Descripci&oacute;n</th>
                  <th style="padding:2px">Monto</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in damages">
                  <td style="padding:2px">{{ item.damage }}</td>
                  <td style="padding:2px">$ {{ numeroFormateado(item.price) }} MXN</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12">
              <h5 class="mb-3">M&eacute;todos de pago</h5>
              <table class="table table-responsive">
                <thead>
                <tr>
                  <th style="padding:2px">M&eacute;todo de pago</th>
                  <th style="padding:2px">Importe pagado</th>
                </tr>
                </thead>
                <tbody>
                <tr v-show="item.depositCash>0">
                  <td style="padding:2px">Pagado en efectivo:</td>
                  <td style="padding:2px">$ {{ numeroFormateado(item.depositCash) }} MXN</td>
                </tr>
                <tr v-show="item.depositTarjet>0">
                  <td style="padding:2px">Pagado por tarjeta:</td>
                  <td style="padding:2px">$ {{ numeroFormateado(item.depositTarjet) }} MXN</td>
                </tr>
                <tr v-show="item.depositTransfer>0">
                  <td style="padding:2px">Pagado por transferencia:</td>
                  <td style="padding:2px">$ {{ numeroFormateado(item.depositTransfer) }} MXN</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <h5 class="mt-3">Tarifas</h5>
          <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-12">
              <h6>M&eacute;todo de pago:</h6>
              <p>{{ item.metodoPago === "Ninguno" ? "Mixto" : item.metodoPago }}</p>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
              <h6>Procedencia:</h6>
              <p>{{ item.procedencia.nombre }}</p>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
              <h6>Protecci&oacute;n:</h6>
              <p>{{ item.proteccion ? item.proteccion.name : "No tiene" }}</p>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
              <h6>D&iacute;as:</h6>
              <p>{{ Diferencia_Fechas(item.fechaSalida, item.fechaRegreso) }}</p>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
              <h6>Tarifa diaria:</h6>
              <p>$ {{
                  numeroFormateado(item.dailyPrice)
                }} MXN</p>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
              <h6>Total de tarifa diaria:</h6>
              <p>$ {{ numeroFormateado(total_diario) }} MXN</p>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
              <h6>Tarifa de protecci&oacute;n:</h6>
              <p>$ {{ numeroFormateado(item.totalProteccion) }} MXN</p>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
              <h6>Tarifa de Drop off:</h6>
              <p>$ {{ numeroFormateado(item.totalDropoff) }} MXN</p>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
              <h6>Tarifa de servicios extras:</h6>
              <p>$ {{ numeroFormateado(item.totalServicios) }} MXN</p>
            </div>
            <div v-if="damages.length>0" class="col-lg-3 col-md-6 col-sm-12">
              <h6>Total de daños:</h6>
              <p>$ {{ numeroFormateado(monto_damage) }} MXN</p>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
              <h6>Subtotal</h6>
              <p>$ {{ numeroFormateado(subtotal) }} MXN</p>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
              <h6>IVA ({{ numeroFormateado(details_porc_iva) }} %):</h6>
              <p>$ {{ numeroFormateado(details_iva) }} MXN</p>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
              <h6>Total de la reserva:</h6>
              <p>$ {{ numeroFormateado(item.total) }} MXN</p>
            </div>
          </div>
        </div>
        <div v-show="pagina===0" class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="close_Details" class="btn btn-secondary d-block w-100" type="button">Cancelar</button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="pagina=1" class="btn btn-danger d-block w-100" type="button">
              Siguiente
            </button>
          </div>
        </div>
        <div v-show="pagina===1" class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="pagina=0" class="btn btn-secondary d-block w-100" type="button">
              Regresar
            </button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="close_Details" class="btn btn-danger d-block w-100" type="button">Cerrar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="Subir_foto text-dark">
    <div v-if="Photo_Modal" :class="['modal', { 'show': Photo_Modal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Subir o quitar fotos
            <i @click="close_Photo" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            <label class="form-label ms-1">Seleccione la foto del veh&iacute;culo</label>
            <div class="d-flex justify-content-between align-items-center">
              <input :class="photo_car===''?'is-invalid':''" @change="onPhoto"
                     class="form-control shadow-none rounded-0 text-black" type="file">
              <button :disabled="disabled_button" class="btn btn-success ms-3" @click="Save_Photo">Guardar</button>
            </div>
            <img class="img-fluid img-cliente" :src="preview_photo" alt="Imagen del auto">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3">
            <label class="form-label ms-1">Listado de im&aacute;genes</label>
            <div class="d-flex justify-content-between align-items-center" v-for="item in photos">
              <img :src="getImageUrl(item.photo)" class="img-list cursor-pointer" alt=""
                   @click="Preview_Photo(item)">
              <button :disabled="disabled_button" class="btn btn-danger" @click="DeletePhoto(item.id)">Eliminar</button>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div v-show="error_vacios" class="col-12 text-center p-2"
               style="background-color: rgba(255,19,28,0.08)">
              <span class="d-flex align-items-center justify-content-center">
                <i class="flaticon-warning me-2 fs-15"></i>
                Debe seleccionar una imagen
              </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="close_Photo" class="btn btn-secondary d-block w-100" type="button">Cancelar</button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="Estatus text-dark">
    <div v-if="Estado_Modal" :class="['modal', { 'show': Estado_Modal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Cerrar contrato <span class="ms-5"
                                  v-if="dias_extras>0 || horas_extras_entrega>0 || damages.length>0">Monto a pagar: $ {{
              numeroFormateado(monto_horas_extras)
            }} MXN</span>
            <i @click="closeEstadoModal" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div v-if="sin_pago" class="row mb-3 text-start">
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3 fecha_entrega_vehiculo">
            <label class="form-label ms-1">Fecha de entrega del veh&iacute;culo</label>
            <VueDatePicker v-model="fecha_entrega" auto-apply locale="es" :dark="this.$store.state.isDarkMode"
                           :min-date="new Date()"
                           teleport-center :state="!error_fecha_entrega" format="dd/MM/yyyy hh:mm aa"
                           time-picker-inline placeholder="Seleccione la fecha"/>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3 litros_en_tanque">
            <label class="form-label ms-1">Cantidad de litros en tanque</label>
            <input :class="combustible_tanque_entrega<0?'is-invalid':'border-success'"
                   v-model="combustible_tanque_entrega" type="number" min="0" required
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 mb-3 kilometraje_llegado">
            <label class="form-label ms-1">Kilometraje de llegada</label>
            <input :class="error_kilometraje_entrega?'is-invalid':'border-success'" v-model="kilometraje_entrega"
                   type="number" min="0" required
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div v-if="dias_reembolso>0"
               class="col-lg-6 col-md-6 col-sm-12 mb-3 cantidad_horas_extras">
            <label class="form-label ms-1">D&iacute;as de reembolso</label>
            <input readonly v-model="dias_reembolso"
                   type="number" min="0"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div v-if="dias_reembolso>0" class="col-lg-6 col-md-6 col-sm-12 mb-3 dias_extras">
            <label class="form-label ms-1">Monto de reembolso</label>
            <input v-model="monto_reembolso" type="number" min="0" readonly
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div v-if="horas_extras_entrega>0 || dias_extras>0"
               class="col-lg-6 col-md-6 col-sm-12 mb-3 cantidad_horas_extras">
            <label class="form-label ms-1">Cantidad de horas extras</label>
            <input readonly v-model="horas_extras_entrega"
                   type="number" min="0"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div v-if="horas_extras_entrega>0 || dias_extras>0" class="col-lg-6 col-md-6 col-sm-12 mb-3 dias_extras">
            <label class="form-label ms-1">D&iacute;as extras</label>
            <input v-model="dias_extras" type="number" min="0" class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3 descripcion">
            <label class="form-label ms-1">Descripci&oacute;n</label>
            <textarea :class="descripcion_entrega=='' || !descripcion_entrega?'is-invalid':'border-success'"
                      v-model="descripcion_entrega"
                      class="form-control shadow-none rounded-0 text-black"></textarea>
          </div>
        </div>
        <div v-else class="row mb-3 text-start">
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-12 text-start">
              <label class="form-label ms-1">Daño<span
                  class="text-danger fw-bold"> *</span></label>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 text-start">
              <label class="form-label ms-1">Monto<span
                  class="text-danger fw-bold"> *</span></label>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-lg-4 col-md-4 col-sm-12">
              <input v-model="damage_description" class="form-control shadow-none rounded-0 text-black">
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <input v-model="damage_price" class="form-control shadow-none rounded-0 text-black">
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <button @click="addDamage" :disabled="save_damage" class="btn btn-success">Adicionar</button>
            </div>
          </div>
          <div class="row">
            <table class="table table-responsive">
              <thead>
              <tr>
                <th>Daño</th>
                <th>Monto</th>
                <th>Acciones</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in damages">
                <td>{{ item.damage }}</td>
                <td>$ {{ numeroFormateado(item.price) }} MXN</td>
                <td>
                  <button data-bs-toggle="tooltip" :disabled="save_damage"
                          style="padding: 0.8rem" @click="deleteDamage(item.id)"
                          data-bs-placement="top"
                          title="Quitar daño" data-container="body" data-animation="true"
                          class="btn text-danger">
                    <i class="flaticon-delete  d-flex justify-content-center align-items-center "></i></button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div v-if="horas_extras_entrega>0 || dias_extras>0 || damages.length>0"
               class="col-lg-12 col-md-12 col-sm-12 mb-3 metodo_pago">
            <label class="form-label ms-1">M&eacute;todo de pago<span class="text-danger fw-bold"> *</span></label>
            <select v-model="metodo_pago_entrega" :class="error_metodo_pago?'is-invalid':'border-success'"
                    class="form-control shadow-none rounded-0 text-black">
              <option disabled :value="null">Seleccione el m&eacute;todo de pago</option>
              <option value="Tarjeta">Tarjeta</option>
              <option value="Efectivo">Efectivo</option>
              <option value="Transferencia Bancaria">Transferencia Bancaria</option>
            </select>
          </div>
          <div v-if="horas_extras_entrega>0 || dias_extras>0 || damages.length>0"
               v-show="metodo_pago_entrega==='Tarjeta'"
               class="col-lg-6 col-md-6 col-sm-12 mb-3 tarjeta_numero">
            <label class="form-label ms-1">N&uacute;mero de la tarjeta<span
                class="text-danger fw-bold"> *</span></label>
            <input v-model="tarjeta_numero" :class="error_tarjeta_numero?'is-invalid':'border-success'"
                   class="form-control shadow-none rounded-0 text-black" maxlength="16">
          </div>
          <div v-if="horas_extras_entrega>0 || dias_extras>0 || damages.length>0"
               v-show="metodo_pago_entrega==='Tarjeta'"
               class="col-lg-6 col-md-6 col-sm-12 mb-3 tarjeta_vencimiento">
            <label class="form-label ms-1">Vencimiento de la tarjeta<span
                class="text-danger fw-bold"> *</span></label>
            <VueDatePicker v-model="fecha_tarjeta" auto-apply locale="es" :dark="this.$store.state.isDarkMode"
                           teleport-center :state="!error_tarjeta_vencimiento"
                           month-picker
                           time-picker-inline placeholder="Seleccione la fecha"
                           :enable-time-picker="false"/>
          </div>
          <div v-if="horas_extras_entrega>0 || dias_extras>0 || damages.length>0"
               v-show="metodo_pago_entrega==='Transferencia Bancaria'"
               class="col-lg-12 col-md-12 col-sm-12 mb-3 concepto">
            <label class="form-label ms-1">Concepto<span class="text-danger fw-bold"> *</span></label>
            <textarea class="form-control shadow-none rounded-0 text-black"
                      :class="error_transferencia_concepto?'is-invalid':'border-success'"
                      v-model="transferencia_concepto"></textarea>
          </div>
          <div v-if="horas_extras_entrega>0 || dias_extras>0 || damages.length>0"
               v-show="metodo_pago_entrega==='Transferencia Bancaria'"
               class="col-lg-6 col-md-6 col-sm-12 mb-3 referencia">
            <label class="form-label ms-1">Referencia<span class="text-danger fw-bold"> *</span></label>
            <input :class="error_transferencia_referencia?'is-invalid':'border-success'"
                   v-model="transferencia_referencia"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div v-if="horas_extras_entrega>0 || dias_extras>0 || damages.length>0"
               v-show="metodo_pago_entrega==='Transferencia Bancaria'"
               class="col-lg-6 col-md-6 col-sm-12 mb-3 banco">
            <label class="form-label ms-1">Banco<span class="text-danger fw-bold"> *</span></label>
            <input :class="error_transferencia_banco?'is-invalid':'border-success'" v-model="transferencia_banco"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button v-if="sin_pago" :disabled="saveChanges" @click="closeEstadoModal"
                    class="btn btn-secondary d-block w-100"
                    type="button">Cancelar
            </button>
            <button v-else @click="sin_pago=true" class="btn btn-secondary d-block w-100" type="button">Regresar
            </button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button :disabled="saveChanges" v-if="!sin_pago" @click="CloseContrato" class="btn btn-danger d-block w-100"
                    type="button">
              <div v-if="saveChanges" class="spinner-border text-dark" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <span v-else>Cerrar contrato</span>
            </button>
            <button v-else @click="sin_pago=false" class="btn btn-danger d-block w-100" type="button">Siguiente</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="PDF">
    <div v-if="Print_Modal" :class="['modal', { 'show': Print_Modal }]">
      <div class="modal-content-print" style="background-color: #fff">
        <i @click="close_Print" class="flaticon-close opacity-10 modal-icon-print fs-15"></i>
        <div ref="contract_pdf" id="contract-pdf" class="mb-1 p-3"
             style="color:#0e0e0e">
          <div class="row">
            <div class="col-3 d-flex justify-content-start align-items-center">
              <img src="@/assets/images/logo3.png"
                   style="width: 120px; height: 100px">
            </div>
            <div class="col-5 text-start">
              <p class="fs-10 fw-bold">Automec&aacute;nica de Tijuana S de RL de CV</p>
              <p class="fs-10 mb-0">Carretera Aeropuerto 16-f Colonia Aeropuerto</p>
              <p class="fs-10 mb-0">C.P. 22404 Tijuana B.C.</p>
              <p class="fs-10 mb-0">Tel.: 664-851-70-16</p>
            </div>
            <div class="col-4 text-start" style="color:#0e0e0e">
              <p class="fs-10 fw-bold">Contrato de renta</p>
              <table>
                <tr>
                  <td colspan="3"><p class="fs-10 fw-bold mb-0" style="color:#0c0c0c">Folio : {{
                      item ? item.contratoNo : ""
                    }}</p></td>
                </tr>
                <tr>
                  <td class="fs-10" style="color:#0c0c0c">Día/ Day<br>{{ getDay(new Date()) }}</td>
                  <td class="fs-10" style="color:#0c0c0c">Mes/ Month<br>{{ getMonth(new Date()) }}</td>
                  <td class="fs-10" style="color:#0c0c0c">Año/ Year<br>{{ getYear(new Date()) }}</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <p class="fs-10 text-uppercase mb-0"><strong>Nombre/ Name:</strong></p>
              <p class="fs-10 mb-0">{{ item ? item.cliente.nombre : "" }} {{ item ? item.cliente.apellidos : "" }}</p>
            </div>
            <div class="col-2">
              <p v-show="item.cliente.rcf" class="fs-10 text-uppercase mb-0"><strong>RFC:</strong></p>
              <p class="fs-10 mb-0">{{ item ? item.cliente.rcf : "" }}</p>
            </div>
            <div class="col-2">
              <p class="fs-10 text-uppercase mb-0"><strong>Auto No.:</strong></p>
              <p class="fs-10 mb-0">{{ item ? item.vehiculo.noEconomico : "" }}</p>
            </div>
            <div class="col-1">
              <p class="fs-10 text-uppercase mb-0"><strong>Color:</strong></p>
              <p class="fs-10 mb-0">{{ item ? item.vehiculo.color : "" }}</p>
            </div>
            <div class="col-2">
              <p class="fs-10 text-uppercase mb-0"><strong>Marca:</strong></p>
              <p class="fs-10 mb-0">{{ item ? item.vehiculo.modelo.marca.name : "" }}</p>
            </div>
            <div class="col-2">
              <p class="fs-10 text-uppercase mb-0"><strong>Modelo:</strong></p>
              <p class="fs-10 mb-0">{{ item ? item.vehiculo.modelo.name : "" }}</p>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-3">
              <p class="fs-10 text-uppercase mb-0"><strong>Domicilio fiscal/ Address:</strong></p>
              <p class="fs-10 mb-0">{{
                  item ? item.cliente.direccionSet.edges.length > 0 ? item.cliente.direccionSet.edges[0].node.direccion : "" : ""
                }}</p>
            </div>
            <div class="col-2">
              <p class="fs-10 text-uppercase mb-0"><strong>CP:</strong></p>
              <p class="fs-10 mb-0">{{
                  item ? item.cliente.direccionSet.edges.length > 0 ? item.cliente.direccionSet.edges[0].node.postal : "" : ""
                }}</p>
            </div>
            <div class="col-2">
              <p class="fs-10 text-uppercase mb-0"><strong>Placas:</strong></p>
              <p class="fs-10 mb-0">{{ item ? item.vehiculo.noPlaca : "" }}</p>
            </div>
            <div class="col-1">
              <p class="fs-10 text-uppercase mb-0"><strong>Ciudad:</strong></p>
              <p class="fs-10 mb-0">{{ item ? item.vehiculo.sucursal.ciudad.name : "" }}</p>
            </div>
            <div class="col-2">
              <p class="fs-10 text-uppercase mb-0"><strong>Estado:</strong></p>
              <p class="fs-10 mb-0">{{ item ? item.vehiculo.sucursal.ciudad.estado.name : "" }}</p>
            </div>
            <div class="col-2">
              <p class="fs-10 text-uppercase mb-0"><strong>Categoría:</strong></p>
              <p class="fs-10 mb-0">{{ item ? item.vehiculo.categoria.name : "" }}</p>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-3">
              <p class="fs-10 text-uppercase mb-0"><strong>TELEFONO/ PHONE:</strong></p>
              <p class="fs-10 mb-0">{{
                  item ? item.cliente.contactoSet.edges.length > 0 ? item.cliente.contactoSet.edges[0].node.telefono : "" : ""
                }}</p>
            </div>
            <div class="col-2">
              <p class="fs-10 text-uppercase mb-0"><strong>CORREO/ EMAIL:</strong></p>
              <p class="fs-10 mb-0">{{ item ? item.cliente.user.email : "" }}</p>
            </div>
            <div class="col-3">
              <p class="fs-10 text-uppercase mb-0"><strong>FECHA DE SALIDA:</strong></p>
              <p class="fs-10 mb-0">{{ item ? formatearFecha(item.fechaSalida) : "" }}</p>
            </div>
            <div class="col-4">
              <p class="fs-10 text-uppercase mb-0"><strong>SUCURSAL DE RECOGIDA:</strong></p>
              <p class="fs-10 mb-0">{{ item ? item.contratoSucursalRecogida.name : "" }}</p>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-3">
              <p class="fs-10 text-uppercase mb-0"><strong>TARJETA CREDITO/ CREDIT CARD:</strong></p>
              <p class="fs-10 mb-0">{{
                  item ? item.tarjetNumber : ""
                }}</p>
            </div>
            <div class="col-2">
              <p class="fs-10 text-uppercase mb-0"><strong>VENCE/ EXPIRE:</strong></p>
              <p class="fs-10 mb-0">{{ item ? getExpireDate(item.tarjetExp) : "" }}</p>
            </div>
            <div class="col-3">
              <p class="fs-10 text-uppercase mb-0"><strong>KM SALIDA:</strong></p>
              <p class="fs-10 mb-0">{{ item ? item.vehiculo.kilometraje : "" }} km</p>
            </div>
            <div class="col-4">
              <p class="fs-10 text-uppercase mb-0"><strong>GAS SALIDA:</strong></p>
              <p class="fs-10 mb-0">{{ item ? item.comb : "" }} lts</p>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-3">
              <p class="fs-10 text-uppercase mb-0"><strong>No LICENCIA/ DRIVER LIC:</strong></p>
              <p class="fs-10 mb-0">{{
                  item ? item.cliente.licenciaSet.edges.length > 0 ? item.cliente.licenciaSet.edges[0].node.numero : "" : ""
                }}</p>
            </div>
            <div class="col-2">
              <p class="fs-10 text-uppercase mb-0"><strong>VENCE/ EXPIRE:</strong></p>
              <p class="fs-10 mb-0">{{
                  item ? item.cliente.licenciaSet.edges.length > 0 ? formatearFechaExpire(item.cliente.licenciaSet.edges[0].node.vencimiento) : "" : ""
                }}</p>
            </div>
            <div class="col-3">
              <p class="fs-10 text-uppercase mb-0"><strong>FECHA DE ENTRADA:</strong></p>
              <p class="fs-10 mb-0">{{ item ? formatearFecha(item.fechaRegreso) : "" }}</p>
            </div>
            <div class="col-4">
              <p class="fs-10 text-uppercase mb-0"><strong>SUCURSAL DE ENTREGA:</strong></p>
              <p class="fs-10 mb-0">{{ item ? item.contratoSucursalEntrega.name : "" }}</p>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-5">
              <p v-show="drivers_info.length>0" class="fs-10 text-uppercase mb-0"><strong>CONDUCTOR ADICIONAL:</strong>
              </p>
              <p v-show="drivers_info.length>0" class="fs-10 mb-0">
                {{ drivers_info.length > 0 ? drivers_info[0].extraDriver.nombre : "" }}
                {{ drivers_info.length > 0 ? drivers_info[0].extraDriver.apellidos : "" }}
              </p>
            </div>
            <div class="col-3">
              <!--              <p v-show="fla" class="fs-10 text-uppercase mb-0"><strong>KM ENTRADA:</strong></p>-->
              <!--              <p class="fs-10 mb-0"></p>-->
            </div>
            <div class="col-4">
              <!--              <p class="fs-10 text-uppercase mb-0"><strong>GAS ENTRADA:</strong></p>-->
              <!--              <p class="fs-10 mb-0"></p>-->
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-3">
              <p v-show="drivers_info.length>0" class="fs-10 text-uppercase mb-0"><strong>No LICENCIA/ DRIVER
                LIC:</strong>
              </p>
              <p v-show="drivers_info.length>0" class="fs-10 mb-0">{{
                  drivers_info.length > 0 ? drivers_info[0].extraDriver.numero : ""
                }}</p>
            </div>
            <div class="col-2">
              <p v-show="drivers_info.length>0" class="fs-10 text-uppercase mb-0"><strong>VENCE/ EXPIRE:</strong></p>
              <p v-show="drivers_info.length>0" class="fs-10 mb-0">{{
                  drivers_info.length > 0 ? formatearFechaExpire(drivers_info[0].extraDriver.vencimiento) : ""
                }}</p>
            </div>
            <div class="col-3">

            </div>
            <div class="col-4">

            </div>
          </div>
          <div class="row border border-danger border-1 p-2 mb-2">
            <div class="col-6">
              <div class="row mb-1 border border-dark border-2">
                <div class="col-8 pe-1 ps-1 d-flex justify-content-between">
                  <p class="fs-10 text-uppercase mb-0"><strong>TARIFAS:</strong></p>
                  <p class="fs-10 text-uppercase mb-0"><strong>PRECIO UNIT:</strong></p>
                </div>
                <div class="col-4 pe-1 ps-1 d-flex justify-content-betweenn">
                  <p class="fs-10 text-uppercase mb-0"><strong>DÍA:</strong></p>
                  <p class="fs-10 text-uppercase mb-0"><strong>IMPORTE:</strong></p>
                </div>
              </div>
              <div class="row">
                <div class="col-8 pe-1 ps-1 d-flex justify-content-between">
                  <p class="fs-10 mb-0">Diaria</p>
                  <p class="fs-10 mb-0">$ {{ item ? numeroFormateado(item.dailyPrice) : "0.00" }} MXN</p>
                </div>
                <div class="col-4 pe-1 ps-1 d-flex justify-content-between">
                  <p class="fs-10 mb-0">X{{ item ? item.dias : "0" }}</p>
                  <p class="fs-10 mb-0">
                    $ {{ item ? numeroFormateado(parseFloat(item.dailyPrice) * parseInt(item.dias)) : "0.00" }} MXN</p>
                </div>
              </div>
              <div class="row">
                <div class="col-8 pe-1 ps-1 d-flex justify-content-between">
                  <p class="fs-10 mb-0">Drop Off</p>
                  <p class="fs-10 mb-0"></p>
                </div>
                <div class="col-4 pe-1 ps-1 d-flex justify-content-between">
                  <p class="fs-10 mb-0"></p>
                  <p class="fs-10 mb-0">
                    $ {{ item ? numeroFormateado(item.totalDropoff) : "0.00" }} MXN</p>
                </div>
              </div>
              <div class="row">
                <div class="col-8 pe-1 ps-1 d-flex justify-content-between">
                  <p class="fs-10 mb-0">Protecciones</p>
                  <p class="fs-10 mb-0">$
                    {{ item ? item.proteccion ? numeroFormateado(item.proteccion.tarifa) : "0.00" : "0.00" }} MXN</p>
                </div>
                <div class="col-4 pe-1 ps-1 d-flex justify-content-between">
                  <p class="fs-10 mb-0">X{{ item ? item.dias : "0" }}</p>
                  <p class="fs-10 mb-0">
                    $ {{ item ? numeroFormateado(item.totalProteccion) : "0.00" }} MXN</p>
                </div>
              </div>
              <div class="row" v-for="(service, index) in item.serviciosContratoSet.edges" :key="index">
                <div class="col-8 pe-1 ps-1 d-flex justify-content-between">
                  <p class="fs-10 mb-0">{{ service.node.servicio.name }} (__________)</p>
                  <p class="fs-10 mb-0">$ {{ numeroFormateado(service.node.servicio.precio) }} MXN</p>
                </div>
                <div class="col-4 pe-1 ps-1 d-flex justify-content-between">
                  <p class="fs-10 mb-0">{{ service.node.servicio.diario ? `X${item.dias}` : "U/P" }}</p>
                  <p class="fs-10 mb-0">
                    $ {{
                      item ? service.node.servicio.diario ? numeroFormateado(parseFloat(service.node.servicio.precio) * parseInt(item.dias)) : numeroFormateado(service.node.servicio.precio) : "0.00"
                    }} MXN</p>
                </div>
              </div>
              <div class="row">
                <div class="col-8 pe-1 ps-1 d-flex justify-content-between">
                  <p class="fs-10 mb-0">Subtotal</p>
                  <p class="fs-10 mb-0"></p>
                </div>
                <div class="col-4 pe-1 ps-1 d-flex justify-content-between">
                  <p class="fs-10 mb-0"></p>
                  <p class="fs-10 mb-0">
                    $ {{
                      item ? numeroFormateado(parseFloat(item.totalDropoff) + parseFloat(item.totalServicios) + parseFloat(item.totalProteccion) + (parseFloat(item.dailyPrice) * parseInt(item.dias))) : "0.00"
                    }} MXN</p>
                </div>
              </div>
              <div class="row">
                <div class="col-8 pe-1 ps-1 d-flex justify-content-between">
                  <p class="fs-10 mb-0">Promoción
                    {{ item ? item.promocion ? "(" + item.promocion.porciento + " %)" : "" : "" }}</p>
                  <p class="fs-10 mb-0 text-center">{{ item ? item.promocion ? item.promocion.codigo : "" : "" }}</p>
                </div>
                <div class="col-4 pe-1 ps-1 d-flex justify-content-between">
                  <p class="fs-10 mb-0"></p>
                  <p class="fs-10 mb-0">
                    <span class="bold">-</span>
                    $ {{
                      item ? item.promocion ? numeroFormateado(item.totalPromocion) : "0.00" : "0.00"
                    }} MXN
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-8 pe-1 ps-1 d-flex justify-content-between">
                  <p class="fs-10 mb-0">IVA</p>
                  <p class="fs-10 mb-0"></p>
                </div>
                <div class="col-4 pe-1 ps-1 d-flex justify-content-between">
                  <p class="fs-10 mb-0"></p>
                  <p class="fs-10 mb-0">
                    $ {{
                      item ? numeroFormateado(item.iva) : "0.00"
                    }} MXN</p>
                </div>
              </div>
              <div class="row border border-dark border-2 mt-1 mb-1">
                <div class="col-8 pe-1 ps-1 d-flex justify-content-between align-items-center">
                  <p class="fs-10 mb-0">TOTAL</p>
                  <p class="fs-10 mb-0"></p>
                </div>
                <div class="col-4 pe-1 ps-1 d-flex justify-content-end">
                  <p class="fs-10 mb-0"></p>
                  <p class="fs-10 mb-0">
                    $ {{
                      item ? numeroFormateado(item.total) : "0.00"
                    }} MXN</p>
                </div>
              </div>
              <div class="row">
                <div class="col-8 pe-1 ps-1 d-flex justify-content-between">
                  <p class="fs-10 mb-0">M&eacute;todo de pago</p>
                  <p class="fs-10 mb-0"></p>
                </div>
                <div class="col-4 pe-1 ps-1 d-flex justify-content-between">
                  <p class="fs-10 mb-0"></p>
                  <p class="fs-10 mb-0">
                    {{ item.metodoPago === "Ninguno" ? "Mixto" : item.metodoPago }}</p>
                </div>
              </div>
              <div class="row" v-show="item.depositCash>0">
                <div class="col-8 pe-1 ps-1 d-flex justify-content-between">
                  <p class="fs-10 mb-0">Pagado en efectivo:</p>
                  <p class="fs-10 mb-0"></p>
                </div>
                <div class="col-4 pe-1 ps-1 d-flex justify-content-between">
                  <p class="fs-10 mb-0"></p>
                  <p class="fs-10 mb-0">
                    $ {{ numeroFormateado(item.depositCash) }} MXN</p>
                </div>
              </div>
              <div class="row" v-show="item.depositTarjet>0">
                <div class="col-8 pe-1 ps-1 d-flex justify-content-between">
                  <p class="fs-10 mb-0">Pagado por tarjeta:</p>
                  <p class="fs-10 mb-0"></p>
                </div>
                <div class="col-4 pe-1 ps-1 d-flex justify-content-between">
                  <p class="fs-10 mb-0"></p>
                  <p class="fs-10 mb-0">
                    $ {{ numeroFormateado(item.depositTarjet) }} MXN</p>
                </div>
              </div>
              <div class="row" v-show="item.depositTransfer>0">
                <div class="col-8 pe-1 ps-1 d-flex justify-content-between">
                  <p class="fs-10 mb-0">Transferencia Bancaria</p>
                  <p class="fs-10 mb-0"></p>
                </div>
                <div class="col-4 pe-1 ps-1 d-flex justify-content-between">
                  <p class="fs-10 mb-0"></p>
                  <p class="fs-10 mb-0">
                    $ {{ numeroFormateado(item.depositTransfer) }} MXN</p>
                </div>
              </div>
              <div class="row">
                <div class="col-8 pe-1 ps-1 d-flex justify-content-between">
                  <p class="fs-10 mb-0 bold">Depósito en garantía</p>
                  <p class="fs-10 mb-0"></p>
                </div>
                <div class="col-4 pe-1 ps-1 d-flex justify-content-between">
                  <p class="fs-10 mb-0"></p>
                  <p class="fs-10 mb-0">
                    $ {{
                      item ? numeroFormateado(item.deposit) : "0.00"
                    }} MXN</p>
                </div>
              </div>
              <div class="row">
                <div class="col-8 pe-1 ps-1 d-flex justify-content-between">
                  <p class="fs-10 mb-0 bold">Tarjeta de garant&iacute;a</p>
                  <p class="fs-10 mb-0"></p>
                </div>
                <div class="col-4 pe-1 ps-1 d-flex justify-content-between">
                  <p class="fs-10 mb-0"></p>
                  <p class="fs-10 mb-0">
                    {{ formatCardNumber(item.depositTarjetNumber) }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-6 pe-1 ps-1 d-flex justify-content-between">
                  <p class="fs-10 mb-0 bold">Autorizaci&oacute;n</p>
                  <p class="fs-10 mb-0"></p>
                </div>
                <div class="col-6 pe-1 ps-1 d-flex justify-content-between">
                  <p class="fs-10 mb-0"></p>
                  <p class="fs-10 mb-0 bold">Vencimiento</p>
                </div>
              </div>
              <div class="row">
                <div class="col-6 pe-1 ps-1 d-flex justify-content-between">
                  <p class="fs-10 mb-0">{{ item.depositTarjetAuth }}</p>
                  <p class="fs-10 mb-0"></p>
                </div>
                <div class="col-6 pe-1 ps-1 d-flex justify-content-between">
                  <p class="fs-10 mb-0"></p>
                  <p class="fs-10 mb-0">{{ getExpireDate(item.depositTarjetExp) }}</p>
                </div>
              </div>
            </div>
            <div class="col-6">
              <p v-show="item.proteccion!==null" class="fs-10 text-uppercase mb-1"><strong>PROTECCIONES
                CONTRATADAS:</strong>
              </p>
              <p v-show="item.proteccion!==null" class="fs-10 mb-1">{{
                  item ? item.proteccion ? item.proteccion.name : "" : ""
                }}</p>
              <p v-show="item.proteccion!==null" class="fs-10 mb-1" style="text-align: justify">
                {{ item ? item.proteccion ? item.proteccion.descripcion : "" : "" }}</p>
              <div v-if="item.proteccion!==null" class="d-flex justify-content-center">
                <div class="w-25 border-top">
                  <p class="fs-10 text-center border-top mb-0">Firma</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6"><p class="fs-10 text-uppercase mb-10"><strong>ESTA UD DE ACUERDO QUE LE ENVIEMOS CORREO O
              WHATSAPP
            </strong></p></div>
            <div class="col-2 d-flex justify-content-between">
              <p class="border-bottom"></p>
              <p class="fs-10 text-uppercase mb-10"><strong>SI
              </strong></p>
            </div>
            <div class="col-2 d-flex justify-content-between">
              <p class="border-bottom"></p>
              <p class="fs-10 text-uppercase mb-10">
                <strong>NO</strong></p>
            </div>

          </div>
          <div class="row mb-2">
            <div class="col-6 p-2 border border-dark border-2">
              <p class="fs-10 text-uppercase mb-30"><strong>ACEPTO LA POLIZA DE SEGUROS DE STREET RENT A CAR Y LAS
                CONDICIONES ESTABLECIDAS CON LA SIGUIENTE FIRMA:</strong></p>
              <div class="d-flex justify-content-center">
                <div class="w-25 border-top">
                  <p class="fs-10 text-center border-top">Firma</p>
                </div>
              </div>
            </div>
            <div class="col-6 p-2 border border-dark border-2">
              <p class="fs-10 text-uppercase mb-2"><strong>CARTA DE AUTORIZACION PARA CARGOS PARA CARGOS A TARJETA DE
                CREDITO </strong></p>
              <p class="fs-10 mb-2">POR MEDIO DE LA PRESENTE AUTORIZO A:</p>
              <p class="fs-10 mb-2 mb-30" style="text-align: justify">Para que dentro de las condiciones que estable el
                contrato e Tarjeta de
                Crédito correspondiente carguen a mi cuenta las cantidades que por concepto de servicio de renta de auto
                se origine, Acepto y pagare los cargos.</p>
              <div class="d-flex justify-content-center">
                <div class="w-25 border-top">
                  <p class="fs-10 text-center border-top mb-0">Firma</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-20">
            <p class="fs-10" style="text-align: justify">EN LA CIUDAD DE TIJUANA BC A LOS _____ DEL MES
              _______________________ DE DEL AÑO _______ DEBO(EMOS)
              Y PAGARE(EMOS)
              INCONDICIONALMENTE POR ESTE PAGARÉ A LA ORDEN DE AUTOMECÁNICA DE TIJUANA DE R.L DE C.V EN LA CIUDAD DE
              TIJUANA B.C. EL DÍA ______ DEL MES _____________________ LA CANTIDAD DE ${{
                numeroFormateado(item.total)
              }} M.N.( M.N.) VALOR RECIBIDO A MI(NUESTRA) ENTERA SATISFACCIÓN. ESTE
              PAGARÉ ESTA SUJETO A LA CONDICIÓN DE QUE AL NO PAGARSE A SU
              VENCIMIENTO SERÁ EXIGIBLE ESE LA FECHA DE VENCIMIENTO DE ESTE DOCUMENTO HASTA EL DÍA DE SU TOTAL
              LIQUIDACIÓN. CAUSADO POR ESTE MOTIVO UN INTERÉS MONATORIO AL TIPO DEL % MENSUAL PAGADERO EN ESTA CIUDAD
              CONJUNTAMENTE CON LA SUERTE PRINCIPAL.</p>
          </div>
          <div class="row mb-50 d-flex justify-content-center">
            <div class="w-25 border-top d-flex justify-content-center">
              <p class="fs-10 text-center mb-0">Firma</p>
            </div>
          </div>
          <p v-if="drivers_info.length>1" id="page2" class="fs-12 text-uppercase mb-0"><strong>CONDUCTORES
            ADICIONALES:</strong></p>
          <div class="row mb-1" v-if="drivers_info.length>1" v-for="(item, index) in drivers_info.slice(1)"
               :key="index">
            <div class="col-4">
              <p class="fs-10 text-uppercase mb-0"><strong>NOMBRE Y APELLIDOS:</strong></p>
              <p class="fs-10 mb-0">{{ item.extraDriver.nombre }} {{ item.extraDriver.apellidos }}</p>
            </div>
            <div class="col-4">
              <p class="fs-10 text-uppercase mb-0"><strong>No LICENCIA/ DRIVER
                LIC:</strong>
              </p>
              <p class="fs-10 mb-0">{{ item.extraDriver.numero }}</p>
            </div>
            <div class="col-4">
              <p class="fs-10 text-uppercase mb-0"><strong>VENCE/ EXPIRE:</strong></p>
              <p class="fs-10 mb-0">{{ formatearFechaExpire(item.extraDriver.vencimiento) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="Comprobante">
    <div v-if="Comprobante_Modal" :class="['modal', { 'show': Comprobante_Modal }]">
      <div id="comprobante-pdf" class="mb-1 p-3" style="color:#0e0e0e">
        <div class="row mb-3">
          <div class="col-4 d-flex justify-content-start align-items-center">
            <img src="@/assets/images/logo3.png"
                 style="width: 120px; height: 100px">
          </div>
          <div class="col-4 text-start">
            <p class="fs-10 fw-bold">Automec&aacute;nica de Tijuana S de RL de CV</p>
            <p class="fs-10 mb-0">Carretera Aeropuerto 16-f Colonia Aeropuerto</p>
            <p class="fs-10 mb-0">C.P. 22404 Tijuana B.C.</p>
            <p class="fs-10 mb-0">Tel.: 664-851-70-16</p>
          </div>
          <div class="col-4 text-start" style="color:#0e0e0e">

          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <p class="fs-10 fw-bold">Recibo de pago de horas extras</p>
          </div>
          <div class="col-4">
            <label class="bold">No. Contrato:</label>
            <p>{{ item.contratoNo }}</p>
          </div>
          <div class="col-4">
            <label class="bold">Fecha actual:</label>
            <p>{{ formatearFecha(new Date()) }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <label class="bold">Nombre:</label>
            <p>{{ item.cliente.nombre }}</p>
          </div>
          <div class="col-4">
            <label class="bold">Apellidos:</label>
            <p>{{ item.cliente.apellidos }}</p>
          </div>
          <div class="col-4">
            <label class="bold">Correo:</label>
            <p>{{ item.cliente.user.email }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <label class="bold">Veh&iacute;culo no.:</label>
            <p>{{ item.vehiculo.noEconomico }}</p>
          </div>
          <div class="col-4">
            <label class="bold">Fecha de salida:</label>
            <p>{{ formatearFecha(item.fechaSalida) }}</p>

          </div>
          <div class="col-4">
            <label class="bold">Fecha de regreso:</label>
            <p>{{ formatearFecha(item.fechaRegreso) }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <label class="bold">Kilometros de entrada:</label>
            <p>{{ kilometraje_entrega }}</p>
          </div>
          <div class="col-4">
            <label class="bold">Gas de entrada:</label>
            <p>{{ combustible_tanque_entrega }}</p>
          </div>
          <div class="col-4">
            <label class="bold">Sucursal de entrada:</label>
            {{ item.contratoSucursalEntrega.name }}
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <div v-if="horas_extras_entrega<=3 && horas_extras_entrega>0">
              <label class="bold">Horas extras:</label>
              <p>{{ horas_extras_entrega }}</p>
            </div>
            <div v-else>
              <label class="bold">D&iacute;as extras:</label>
              <p>{{ dias_extras }}</p>
            </div>
          </div>
          <div class="col-4">
            <div v-if="horas_extras_entrega<=3 && horas_extras_entrega>0">
              <label>Tarifa de hora extra:</label>
              <p>$ {{ numeroFormateado(parseFloat(item.dailyPrice) / 4) }} MXN</p>
            </div>
            <div v-else>
              <label class="bold">Tarifa diaria:</label>
              <p>$ {{ numeroFormateado(item.dailyPrice) }} MXN</p>
            </div>
          </div>
          <div class="col-4">
            <label class="bold">M&eacute;todo de pago:</label>
            <p> {{ metodo_pago_entrega }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-4" v-if="damages.length>0">
            <label class="bold">Monto por daños:</label>
            <p> $ {{ numeroFormateado(monto_damage) }} MXN</p>
          </div>
          <div class="col-4">

          </div>
          <div class="col-4">
            <label class="bold">Monto pagado:</label>
            <p>$ {{ numeroFormateado(monto_horas_extras) }} MXN</p>
          </div>
        </div>
        <div class="row" v-if="monto_reembolso>0">
          <div class="col-4">
            <label class="bold">D&iacute;as de reembolso:</label>
            <p> $ {{ numeroFormateado(monto_reembolso) }} MXN</p>
          </div>
          <div class="col-4">

          </div>
          <div class="col-4">
            <label class="bold">Monto de reembolso:</label>
            <p> {{ dias_reembolso }} </p>
          </div>
        </div>
        <div v-if="damages.length>0" class="row">
          <div class="col-lg-8 col-md-6 col-sm-6">
            <p class="bold" style="color:#0e0e0e; margin-bottom:0px">Daño</p>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6">
            <p class="bold" style="color:#0e0e0e; margin-bottom:0px">Monto</p>
          </div>
        </div>
        <div v-if="damages.length>0" class="row" v-for="item in damages">
          <div class="col-lg-8 col-md-6 col-sm-6">
            <p style="color:#0e0e0e; margin-bottom:0px">{{ item.damage }}</p>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6">
            <p style="color:#0e0e0e; margin-bottom:0px">$ {{ numeroFormateado(item.price) }} MXN</p>
          </div>
        </div>
        <div class="row margin-firmas">
          <div class="col-4">
            <label class="bold">Cliente:</label>
            <p>{{ item.cliente.nombre }} {{ item.cliente.apellidos }}</p>
          </div>
          <div class="col-4">

          </div>
          <div class="col-4">
            <label class="bold">Agente:</label>
            <p>{{ this.$store.state.firstName }} {{ this.$store.state.lastName }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="Subir_foto text-dark">
    <div v-if="dialogInvoice" :class="['modal', { 'show': dialogInvoice }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Generar factura
            <i @click="()=>dialogInvoice=false" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label for="person" class="form-label ms-1">Persona</label>
            <select id="person" class="form-control form-select" v-model="objInvoice.person" required
                    :disabled="loadingInvoice" :class="objInvoice.person === ''?'is-invalid':'is-valid'">
              <option value="P">F&iacute;sica</option>
              <option value="M">Moral</option>
            </select>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label for="reason" class="form-label ms-1">Raz&oacute;n social</label>
            <input id="reason" class="form-control" v-model="objInvoice.socialReason" required
                   :disabled="loadingInvoice"
                   :class="objInvoice.socialReason === '' || objInvoice.socialReason.length === 0?'is-invalid':'is-valid'"/>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label for="cfdi" class="form-label ms-1">Uso de CFDI</label>
            <select id="cfdi" class="form-control form-select" v-model="objInvoice.cfdiCost"
                    :class="objInvoice.cfdiCost===''?'is-invalid':'is-valid'">
              <option value="CP01" selected>Pago</option>
              <option value="G03">Gastos en general</option>
            </select>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3" v-if="issuers.length>1">
            <label for="issuer" class="form-label ms-1">Emisor</label>
            <select id="issuer" class="form-control form-select" v-model="objInvoice.issuer" required
                    :disabled="loadingInvoice || loadingIssuers"
                    :class="objInvoice.issuer === ''?'is-invalid':'is-valid'">
              <option disabled selected>{{ loadingIssuers ? 'Cargando...' : 'Seleccione un emisor' }}</option>
              <option v-for="issuer in issuers" :value="issuer.id" :key="issuer.id">{{ issuer.name }}</option>
            </select>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label for="client" class="form-label ms-1">Nombre del cliente</label>
            <input id="client" class="form-control" v-model="objInvoice.client" required
                   :disabled="loadingInvoice || loadingAutcompleteClient"
                   :class="objInvoice.client === ''?'is-invalid':'is-valid'"/>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label for="email" class="form-label ms-1">Email de facturaci&oacute;n</label>
            <input id="email" class="form-control" v-model="objInvoice.email" required
                   :disabled="loadingInvoice || loadingAutcompleteClient"
                   :class="!(/^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(objInvoice.email))?'is-invalid':'is-valid'"/>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label for="rfc" class="form-label ms-1">RFC</label>
            <input id="rfc" class="form-control" v-model="objInvoice.rfc" required
                   :disabled="loadingInvoice || loadingAutcompleteClient" maxlength="12"
                   :class="objInvoice.rfc === '' || objInvoice.rfc.length !== 12 ||
                   !(/[A-Z&Ñ]{3,4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]/.test(objInvoice.rfc))?'is-invalid':'is-valid'"/>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label for="colonia" class="form-label ms-1">Colonia</label>
            <input id="colonia" class="form-control" v-model="objInvoice.colonia" required
                   :disabled="loadingInvoice" :class="objInvoice.colonia === ''?'is-invalid':'is-valid'"/>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label for="state" class="form-label ms-1">Estado</label>
            <input id="state" class="form-control" v-model="objInvoice.state" required
                   :disabled="loadingInvoice || loadingAutcompleteClient"
                   :class="objInvoice.state === ''?'is-invalid':'is-valid'"/>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label for="municipality" class="form-label ms-1">Municipio</label>
            <input id="municipality" class="form-control" v-model="objInvoice.municipality" required
                   :disabled="loadingInvoice || loadingAutcompleteClient"
                   :class="objInvoice.municipality === ''?'is-invalid':'is-valid'"/>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label for="postal" class="form-label ms-1">C&oacute;digo postal</label>
            <input id="postal" class="form-control" v-model="objInvoice.postal" required type="number"
                   :disabled="loadingInvoice || loadingAutcompleteClient" :class="objInvoice.postal === null || objInvoice.postal<=10000
                   || objInvoice.postal>99999?'is-invalid':'is-valid'"/>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label for="postal" class="form-label ms-1">Direcci&oacute;n fiscal</label>
            <input id="postal" class="form-control" v-model="objInvoice.fiscalAddress" required
                   :disabled="loadingInvoice || loadingAutcompleteClient"
                   :class="objInvoice.fiscalAddress === ''?'is-invalid':'is-valid'"/>
          </div>
        </div>
        <div class="alert alert-danger d-flex align-items-center" role="alert" v-if="errorInvoice">
          <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:">
            <use xlink:href="#exclamation-triangle-fill"/>
          </svg>
          <div>
            {{ errorInvoiceMsg }}
          </div>
          <button type="button" class="btn btn-close" style="margin-left:auto; margin-right: 0;"
                  data-bs-dismiss="alert" aria-label="Close"
                  @click="errorInvoice=false"></button>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="()=>dialogInvoice=false" class="btn btn-secondary d-block w-100"
                    :disabled="loadingInvoice" type="button">Cancelar
            </button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="createInvoiceAction" class="btn btn-danger d-block w-100"
                    :disabled="loadingInvoice || isValidDataInvoice || loadingAutcompleteClient" type="button">
              <div v-if="loadingInvoice" class="spinner-border text-secondary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <span v-else>Generar</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import moment from "moment";
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'
import MazInputPrice from 'maz-ui/components/MazInputPrice'
import {
  cancelContract,
  closeContract,
  createContract,
  createInvoice,
  dropOffAction,
  getAllAutoForContract,
  getAllClientsForContract,
  getAllMarcaForContract,
  getAutoByIdForContract,
  getBranchOfficeForContract,
  getCategoriesForContract,
  getCategoryByIdForContract,
  getIvaForSuc,
  getOriginsForContract,
  getProtectionByIdForContract,
  getProtectionsById,
  getProtectionsForContract,
  getReserveForContract,
  getServiceFee,
  getServicesForContract,
  isHightSeason,
  searchContractByIdForDetails,
  searchContractByIdForEdit,
  searchContracts,
  searchIssuersConfigActive,
  searchNextContracts,
  searchPrevContracts,
  serviceByIdForContract,
  updateContract,
  isConfigActive,
  getDataAutocompleteClient,
  clientConfigInvoices,
  createExtraDriver,
  guardarReservaPdf,
  savePdfContract,
  getCarReserved,
  getContractFilesAdmin,
  uploadPhotoContract,
  deletePhotoContract,
  extraDriverByContract,
  getDamagesByContract,
  addDamage,
  deleteDamage,
  createExtraDriverContract, deleteExtraDriver, searchContractsPromotions
} from "@/actions";
import {decodeBase64} from "@/util";
import html2pdf from "html2pdf.js";
import {srac_url_image} from "@/actions/constants";

const auxInvoice = {
  cfdiCost: "",
  person: "M",
  socialReason: "",
  contract: "",
  issuer: "",
  rfc: "",
  client: "",
  colonia: "",
  state: "",
  postal: null,
  municipality: "",
  fiscalAddress: "",
  email: ""
}
export default {
  name: "ContratoOpenList",
  components: {
    MazPhoneNumberInput,
    MazInputPrice
  },
  data() {
    return {
      error_monto_total: false,
      monto_reembolso: 0,
      dias_reembolso: 0,

      drivers_update: [],
      monto_damage: 0,
      save_damage: false,
      contract_id: 0,
      damage_description: "",
      damage_price: 0,
      damages: [],
      Comprobante_Modal: false,
      dias_extras: 0,

      drivers_info: [],
      selectedPayments: {
        efectivo: false,
        tarjeta: false,
        transferencia: false
      },
      drivers: [],
      drivers_id: [],
      deposit_tarjet: 0,
      deposit_cash: 0,
      deposit_transfer: 0,

      photo_input: "",
      disabled_button: false,
      id_to_upload: 0,
      photo_car: "",
      Photo_Modal: false,
      photos: [],
      preview_photo: null,

      marca_reservada: {
        name: "",
        id: 0
      },
      car_reserved: {
        no_econ: "",
        id: 0
      },
      montoPromocion: 0,
      reserva: null,
      errordepositTarjetExp: false,
      errordepositTarjetAuth: false,
      errordepositTarjetNumber: false,
      depositTarjetAuth: "",
      depositTarjetExp: null,
      depositTarjetNumber: "",
      error_licencia: false,
      licencia: "",
      error_vencimiento: false,
      vencimiento: "",
      error_ciudad: false,
      ciudad: "",
      error_estado: false,
      state: "",
      error_pais: false,
      pais: "",
      error_direccion: false,
      direccion: "",
      garantia: 0,
      sub_total: 0,
      error_garantia: false,
      category_name: "",
      subtotal: 0,
      iva_pdf: 0,
      loadingDif: false,
      objInvoice: Object.assign({}, auxInvoice),
      itemInvoice: {},
      dialogInvoice: false,
      loadingAutcompleteClient: false,
      issuers: [],
      loadingInvoice: false,
      loadingIssuers: false,

      pago_pendiente: 0,
      monto_pagado: 0,
      init: false,
      price_extra: 0,
      error_diario: false,
      details_iva: 0,
      details_porc_iva: 0,
      Print_Modal: false,
      all_serivicios_extras: [],
      all_protecciones: [],
      contratosByName: [],
      is_higth_season: false,
      total_diario: 0,
      sucursalesApp: [],
      marcasByCategoria: [],
      estado: 0,
      Estado_Modal: false,
      categoriasByName: [],
      proteccionesApp: [],
      serviciosExtrasApp: [],
      procedenciaByName: [],
      itemsPerPage: 10,
      currentPage: 1,
      search: "",
      Nuevo_Modal: false,
      accion: "nuevo",
      ventana: 0,
      error_vacios: false,
      currentStep: 1,
      steps: [1, 2, 3, 4],
      Details_Modal: false,
      pagina: 0,
      id: 0,
      item: null,

      errorInvoice: false,
      errorInvoiceMsg: "",

      nombre: "",
      apellidos: "",
      email: "",
      telefono: "",
      sucursal_recogida: 0,
      sucursal_entrega: 0,
      fecha_salida: null,
      fecha_regreso: null,
      categoria: null,
      proteccion: null,
      servicios: 0,
      tarjeta_numero: "",
      tarjeta_autorizacion: "",
      tarjeta_vencimiento: null,
      metodo_pago: null,
      fecha_tarjeta: null,
      efectivo: 0,
      codigo: '',
      comentarios: "",
      procedencia: 0,

      error_nombre: true,
      error_apellidos: true,
      error_email: true,
      error_telefono: true,
      error_sucursal_recogida: true,
      error_sucursal_entrega: true,
      error_categoria: true,
      error_proteccion: true,
      error_servicios: true,
      error_tarjeta_numero: true,
      error_tarjeta_autorizacion: true,
      error_expiracion: true,
      error_metodo_pago: true,
      error_fecha_salida: true,
      error_fecha_regreso: true,
      error_tarjeta_vencimiento: true,
      error_efectivo: true,
      tipo_pago: 'tarjeta',
      error_procedencia: true,
      error_transferencia_concepto: true,
      error_transferencia_referencia: true,
      error_transferencia_banco: true,
      error_vehiculo: true,
      error_marca: true,

      servicios_extras: [],

      total: 0,
      tarifa_dropoff: 0,
      tarifa_servicios: 0,
      tarifa_proteccion: 0,
      tarifa_iva: 0,
      porciento_iva: 0,
      tarifa_diaria: 0,
      dias: 0,
      cargando: false,
      transferencia_concepto: "",
      transferencia_referencia: "",
      transferencia_banco: "",
      Update_Modal: false,
      showSubmenu: false,
      deshabilitado: false,
      autocompleteItems: [],
      clientesByName: [],
      vehiculosByName: [],
      vehiculo: 0,
      no_placa: "",
      no_economico: "",
      kilometraje: "",
      modelo: "",
      gasolina: 0,
      marca: 0,
      reserva_id: 0,
      conductor_extra: false,
      nombre_extra: "",
      apellidos_extra: "",
      tipo_licencia_extra: "",
      num_licencia_extra: "",
      vencimiento_licencia_extra: null,
      estatus: "Todos",
      saveChanges: false,

      fecha_entrega: null,
      combustible_tanque_entrega: 0,
      kilometraje_entrega: 0,
      horas_extras_entrega: 0,
      descripcion_entrega: "",
      tarifas_horas_extras: 0,
      monto_horas_extras: 0,
      metodo_pago_entrega: "",
      error_fecha_entrega: true,
      error_combustible_tanque_entrega: true,
      error_kilometraje_entrega: true,
      error_tarifas_horas_extras: true,
      error_horas_extras_entrega: true,
      error_descripcion_entrega: true,
      error_monto_horas_extras: true,
      sin_pago: true,

      hasNextPage: false,
      hasPreviousPage: false,
      startCursor: "",
      lastStartCursor: "",
      endCursor: "",
      lastEndCursor: "",
      cant: 10,
      items: [],
      loading: true,
      toast: Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        customClass: {
          popup: 'bg-white',
          title: 'text-dark',
        },
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        }
      }),
    }
  },
  created() {
    if (this.$store.state.reserva !== null) {
      this.init = true
      const id_reservation = decodeBase64(this.$store.state.reserva.id);
      this.reserva = this.$store.state.reserva
      getReserveForContract(id_reservation).then(response => {
        const reserva = response.data.data.reservaById;
        this.procedencia = reserva.procedencia.id;
        this.sucursal_recogida = decodeBase64(reserva.reservaSucursalRecogida.id);
        this.sucursal_entrega = decodeBase64(reserva.reservaSucursalEntrega.id)
        this.fecha_salida = reserva.fechaSalida;
        this.fecha_regreso = reserva.fechaRegreso;
        this.dias = reserva.dias
        this.monto_pagado = reserva.montoAPagar
        this.pago_pendiente = parseFloat(reserva.total) - parseFloat(reserva.montoAPagar)
        this.categoria = decodeBase64(reserva.categoria.id);
        this.proteccion = reserva.proteccion ? reserva.proteccion.id : null;
        this.servicios_extras = reserva.serviciosReservaSet.edges.map(e => {
          return decodeBase64(e.node.servicio.id);
        });
        if (this.servicios_extras.length > 0) {
          getServiceFee({
            servicios: this.servicios_extras,
            dias: reserva.dias
          }).then(response => {
            this.tarifa_servicios = response.data.data.tarifaServicios;
            this.Calcular_Total();
            this.servicios = 0;
          })
        }
        this.metodo_pago = reserva.metodoPago;
        this.tarifa_diaria = parseFloat(reserva.priceExtra);
        this.reserva_id = reserva.id;
        this.selectItem(reserva.cliente);
        this.codigo = "MX";
        this.openNuevo();
        this.$store.state.reserva = null;
      }).catch(err => console.log(err));
    }
  },
  watch: {
    nombre(value) {
      if (value !== "") {
        this.error_nombre = false
      }
    },
    apellidos(value) {
      if (value !== "") {
        this.error_apellidos = false
      }
    },
    email(value) {
      if (value !== "" && /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(value)) {
        this.error_email = false
      }
    },
    telefono(value) {
      if (value !== "") {
        this.error_telefono = false
      }
    },
    fecha_salida(value) {
      if (this.accion === "nuevo") {
        if (value !== null && this.Diferencia_Fechas(Date.now(), value) >= 1 && this.Diferencia_Fechas(value, this.fecha_regreso) >= 1) {
          this.error_fecha_salida = false
          this.error_fecha_regreso = false
          this.dias = parseInt(this.Diferencia_Fechas(value, this.fecha_regreso))
        }
      } else {
        if (value !== null && this.Diferencia_Fechas(value, this.fecha_regreso) >= 1) {
          this.error_fecha_salida = false
          this.error_fecha_regreso = false
          this.dias = parseInt(this.Diferencia_Fechas(value, this.fecha_regreso))
        }
      }
      if (value !== null) {
        this.getMarcas()
        this.getCars()
        isHightSeason(value).then(response => {
          this.is_higth_season = response.data.data.isHighSeason
        })
      }
    },
    fecha_regreso(value) {
      if (value !== null && this.Diferencia_Fechas(this.fecha_salida, value) >= 1) {
        this.error_fecha_salida = false
        this.error_fecha_regreso = false
        this.dias = parseInt(this.Diferencia_Fechas(this.fecha_salida, value))
        this.getMarcas()
        this.getCars()
      }
    },
    servicios(value) {
      if (value !== 0) {
        serviceByIdForContract(value).then(resp => {
          const serv = resp.data.data.servicioById;
          if (serv.name.toLowerCase().includes('conductor')) {
            this.conductor_extra = true;
          }
        });
        this.servicios_extras.push(parseInt(value));
        if (this.servicios_extras.length > 0) {
          getServiceFee({
            servicios: this.servicios_extras,
            dias: this.dias
          }).then(response => {
            this.tarifa_servicios = response.data.data.tarifaServicios;
            this.Calcular_Total();
            this.servicios = 0;
          })
        }
      }
    },
    metodo_pago(value) {
      if (value !== null) {
        this.error_metodo_pago = false;
      }
    },
    sucursal_recogida(value) {
      if (value !== 0) {
        this.error_sucursal_recogida = false;
        getIvaForSuc(value).then(resp => {
          this.porciento_iva = resp.data.data.ivaBySucursal
        })
        this.Calcular_Dropoff();
        this.getMarcas()
        this.getCars()
      }
    },
    sucursal_entrega(value) {
      if (value !== 0) {
        this.error_sucursal_entrega = false;
        this.Calcular_Dropoff();
      }
    },
    categoria(value) {
      if (value !== null) {
        const aux = decodeBase64(value);
        getCategoryByIdForContract(aux).then(response => {
          this.error_categoria = false
          this.price_extra = this.is_higth_season ? response.data.data.categoriaById.tarifaAlta : response.data.data.categoriaById.tarifaBaja
          this.category_name = response.data.data.categoriaById.name
          if (!this.init) {
            this.tarifa_diaria = this.is_higth_season ? response.data.data.categoriaById.tarifaAlta : response.data.data.categoriaById.tarifaBaja
          }
          this.init = false
          this.Calcular_Total()
        }).catch(err => console.log(err));
        this.getCars()
        this.getMarcas()
      }
    },
    proteccion(value) {
      if (value !== null) {
        getProtectionByIdForContract(value).then(response => {
          this.tarifa_proteccion = parseFloat(response.data.data.proteccionById.tarifa) * parseInt(this.dias)
          this.Calcular_Total()
        }).catch(err => console.log(err));
      } else {
        this.tarifa_proteccion = 0
        this.Calcular_Total()
      }
    },
    efectivo(value) {
      if (value > 0) {
        this.error_efectivo = false
      }
    },
    metodo_pago_entrega(value) {
      this.error_metodo_pago = value == '';
    },
    procedencia(value) {
      if (value !== 0) {
        this.error_procedencia = false
      }
    },
    tarjeta_autorizacion(value) {
      if (value !== "") {
        this.error_tarjeta_autorizacion = false
      }
    },
    tarjeta_numero(value) {
      if (value !== "") {
        this.error_tarjeta_numero = false
      }
    },
    fecha_tarjeta(value) {
      if (value !== null && this.Diferencia_Fechas(Date.now(), this.fecha_tarjeta) >= 1) {
        this.error_tarjeta_vencimiento = false
      }
    },
    transferencia_concepto(value) {
      if (value !== "") {
        this.error_transferencia_concepto = false
      }
    },
    transferencia_referencia(value) {
      if (value !== "") {
        this.error_transferencia_referencia = false
      }
    },
    transferencia_banco(value) {
      if (value !== "") {
        this.error_transferencia_banco = false
      }
    },
    vehiculo(value) {
      if (value !== 0) {
        this.error_vehiculo = false;
        getAutoByIdForContract(value).then(response => {
          const auto = response.data.data.vehiculoById
          this.no_placa = auto.noPlaca
          this.no_economico = auto.noEconomico
          this.modelo = auto.modelo.name
          this.kilometraje = auto.kilometraje
        }).catch(err => console.log(err));
      }
    },
    marca(value) {
      if (value !== 0) {
        this.error_marca = false
      }
    },
    fecha_entrega(value) {
      if (value !== null) {
        this.error_fecha_entrega = false
      }
    },
    combustible_tanque_entrega(value) {

    },
    kilometraje_entrega(value) {
      if (value > 0 && value != '') {
        this.error_kilometraje_entrega = false
      } else {
        this.error_kilometraje_entrega = true
      }
    },
    horas_extras_entrega(value) {
      if (this.horas_extras_entrega > 0 && this.tarifas_horas_extras > 0) {
        this.monto_horas_extras = this.horas_extras_entrega * this.tarifas_horas_extras
        this.efectivo = this.horas_extras_entrega * this.tarifas_horas_extras
      }
    },
    descripcion_entrega(value) {

    },
    tarifas_horas_extras(value) {
      if (this.horas_extras_entrega > 0 && this.tarifas_horas_extras > 0) {
        this.monto_horas_extras = this.horas_extras_entrega * this.tarifas_horas_extras
        this.efectivo = this.horas_extras_entrega * this.tarifas_horas_extras
        this.error_tarifas_horas_extras = false
      }
    },
    monto_horas_extras(value) {
      if (value > 0) {
        this.error_monto_horas_extras = false
      }
    },
    reserva() {
      this.getReservedCar()
    },
    monto_damage(value) {
      console.log(value)
    },
    selectedPayments() {
      if (this.selectedPayments.transferencia === false) {
        this.deposit_transfer = 0
      }
      if (this.selectedPayments.efectivo === false) {
        this.deposit_cash = 0
      }
      if (this.selectedPayments.tarjeta === false) {
        this.deposit_tarjet = 0
      }
    },

    items() {
      this.cargando = false;
    },
    cant() {
      this.loadDatas(true);
    },
    search() {
      this.loadDatas(true)
    }
  },
  methods: {
    openUpdate(item) {
      this.cargando = true
      this.ventana = 0;
      this.currentStep = 1;
      this.getBranchsOffice();
      this.getAllClients();
      this.getCategories();
      this.getProtections();
      this.getServices();
      this.getOrigins();
      this.getExtraDrivers(decodeBase64(item.id));
      searchContractByIdForEdit(item.id).then(response => {
        const reservation = response.data.data.searchContractById;
        this.id = decodeBase64(item.id);
        this.nombre = reservation.cliente.nombre;
        this.apellidos = reservation.cliente.apellidos;
        this.email = reservation.cliente.user.email;
        this.telefono = reservation.cliente.contactoSet.edges.length > 0 ? reservation.cliente.contactoSet.edges[0].node.telefono : "";
        this.codigo = reservation.cliente.contactoSet.edges.length > 0 ? reservation.cliente.contactoSet.edges[0].node.codigo : "";
        this.state = reservation.cliente.direccionSet.edges.length > 0 ? reservation.cliente.direccionSet.edges[0].node.estado : "";
        this.ciudad = reservation.cliente.direccionSet.edges.length > 0 ? reservation.cliente.direccionSet.edges[0].node.ciudad : "";
        this.pais = reservation.cliente.direccionSet.edges.length > 0 ? reservation.cliente.direccionSet.edges[0].node.pais : "";
        this.direccion = reservation.cliente.direccionSet.edges.length > 0 ? reservation.cliente.direccionSet.edges[0].node.direccion : "";
        this.licencia = reservation.cliente.licenciaSet.edges.length > 0 ? reservation.cliente.licenciaSet.edges[0].node.numero : "";
        this.vencimiento = reservation.cliente.licenciaSet.edges.length > 0 ? new Date(reservation.cliente.licenciaSet.edges[0].node.vencimiento) : "";
        this.sucursal_recogida = decodeBase64(reservation.contratoSucursalRecogida.id);
        this.sucursal_entrega = decodeBase64(reservation.contratoSucursalEntrega.id);
        this.fecha_salida = reservation.fechaSalida;
        this.fecha_regreso = reservation.fechaRegreso;
        this.categoria = decodeBase64(reservation.vehiculo.categoria.id);
        this.proteccion = reservation.proteccion ? reservation.proteccion.id : null;
        this.comentarios = reservation.comentario;
        this.procedencia = reservation.procedencia.id;
        this.metodo_pago = reservation.metodoPago;
        this.selectedPayments.efectivo = reservation.depositCash > 0
        this.selectedPayments.transferencia = reservation.depositTransfer > 0
        this.selectedPayments.tarjeta = reservation.depositTarjet > 0
        this.deposit_cash = reservation.depositCash
        this.deposit_tarjet = reservation.depositTarjet
        this.deposit_transfer = reservation.depositTransfer
        this.efectivo = reservation.total;
        this.tarifa_servicios = reservation.totalServicios;
        this.marca = decodeBase64(reservation.vehiculo.modelo.marca.id);
        this.vehiculo = decodeBase64(reservation.vehiculo.id);
        this.no_placa = reservation.vehiculo.noPlaca;
        this.kilometraje = reservation.vehiculo.kilometraje;
        this.transferencia_concepto = reservation.transferConcept;
        this.transferencia_referencia = reservation.transferReference;
        this.transferencia_banco = reservation.transferBank;
        this.tarjeta_numero = reservation.tarjetNumber;
        this.fecha_tarjeta = reservation.tarjetExp;
        this.gasolina = reservation.comb;
        this.montoPromocion = reservation.totalPromocion
        this.tarifa_diaria = reservation.dailyPrice;
        this.garantia = reservation.deposit
        this.depositTarjetAuth = reservation.depositTarjetAuth
        this.depositTarjetNumber = reservation.depositTarjetNumber
        this.depositTarjetExp = reservation.depositTarjetExp
        this.servicios_extras = reservation.serviciosContratoSet.edges.map(e => {
          return decodeBase64(e.node.servicio.id);
        });
        this.Update_Modal = true;
      }).catch(err => console.log(err)).finally(() => {
        this.cargando = false;
      });
    },
    getExtraDrivers(id) {
      extraDriverByContract(id).then(response => {
        this.drivers_update = response.data.data.extraDriverByContract
      })
    },
    createExtraDriver() {
      if (this.nombre_extra === "" || this.apellidos_extra === "" || this.tipo_licencia_extra === "" ||
          this.num_licencia_extra === "" || this.vencimiento_licencia_extra === null) {
        this.error_vacios = true
        return false
      }
      this.error_vacios = false
      createExtraDriverContract({
        id: this.id,
        nombre: this.nombre_extra,
        apellidos: this.apellidos_extra,
        numero: this.num_licencia_extra,
        vencimiento: this.vencimiento_licencia_extra,
        clase: this.tipo_licencia_extra
      }).then(response => {
        if (response.data.data.createExtraDriverContract) {
          this.getExtraDrivers(this.id)
        }
      })
    },
    deleteExtraDriver(id) {
      deleteExtraDriver(id).then(response => {
        if (response.data.data.deleteExtraDriver.success) {
          this.getExtraDrivers(this.id)
        }
      })
    },
    Close_nuevo() {
      this.conductor_extra = false
      this.nombre = ""
      this.apellidos = ""
      this.email = ""
      this.reserva_id = 0
      this.telefono = ""
      this.sucursal_recogida = 0
      this.sucursal_entrega = 0
      this.fecha_salida = null
      this.fecha_regreso = null
      this.categoria = null
      this.proteccion = null
      this.servicios = 0
      this.tarjeta_numero = ""
      this.tarjeta_autorizacion = ""
      this.tarjeta_vencimiento = null
      this.transferencia_concepto = ""
      this.transferencia_banco = ""
      this.transferencia_referencia = ""
      this.gasolina = 0
      this.marca = ""
      this.no_placa = ""
      this.kilometraje = 0
      this.no_economico = ""
      this.metodo_pago = null
      this.fecha_tarjeta = null
      this.efectivo = 0
      this.ventana = 0;
      this.currentStep = 1;
      this.Nuevo_Modal = false;
      this.Update_Modal = false;
      this.procedencia = 0
      this.deposit_transfer = 0
      this.deposit_cash = 0
      this.deposit_transfer = 0
      this.selectedPayments.efectivo = false
      this.selectedPayments.tarjeta = false
      this.selectedPayments.transferencia = false
      this.drivers = []
      this.drivers_update = []

      this.error_nombre = true
      this.error_apellidos = true
      this.error_email = true
      this.error_telefono = true
      this.error_sucursal_recogida = true
      this.error_sucursal_entrega = true
      this.error_categoria = true
      this.error_proteccion = true
      this.error_servicios = true
      this.error_tarjeta_numero = true
      this.error_tarjeta_autorizacion = true
      this.error_expiracion = true
      this.error_metodo_pago = true
      this.error_fecha_salida = true
      this.error_fecha_regreso = true
      this.error_efectivo = true
      this.error_procedencia = true
      this.error_vacios = false

      this.total = 0
      this.tarifa_dropoff = 0
      this.tarifa_servicios = 0
      this.tarifa_proteccion = 0
      this.tarifa_iva = 100.00
      this.tarifa_diaria = 0
      this.dias = 0
      this.servicios_extras = []
      this.reserva = null
    },
    Editar() {
      let validacion = true
      if (parseFloat(this.deposit_tarjet) + parseFloat(this.deposit_cash) + parseFloat(this.deposit_transfer) !== this.total) {
        this.error_monto_total = true
        return false
      }
      this.error_monto_total = false
      if (this.depositTarjetExp === null || this.Diferencia_Fechas(Date.now(), this.depositTarjetExp) < 1) {
        this.validacion = false
        this.errordepositTarjetExp = true
      }
      this.errordepositTarjetExp = false
      if (this.depositTarjetAuth === "") {
        this.validacion = false
        this.errordepositTarjetAuth = true
      }
      this.errordepositTarjetAuth = false
      if (this.depositTarjetNumber === "") {
        this.validacion = false
        this.errordepositTarjetNumber = true
      }
      this.errordepositTarjetNumber = false
      if (validacion) {
        this.saveChanges = true;
        updateContract({
          id: this.id,
          nombre: this.nombre,
          apellidos: this.apellidos,
          telefono: this.telefono,
          codigoTelefono: this.codigo,
          correo: this.email,
          comentario: this.comentarios,
          sucursalRecogida: this.sucursal_recogida,
          sucursalEntrega: this.sucursal_entrega,
          fechaSalida: this.fecha_salida,
          fechaRegreso: this.fecha_regreso,
          proteccion: this.proteccion !== null ? this.proteccion : 0,
          metodoPago: this.getPaymentMethod(),
          procedencia: this.procedencia,
          total: this.total,
          servicios: this.servicios_extras,
          dias: this.dias,
          creator: this.$store.state.userid,
          vehiculo: this.vehiculo,
          transferConcept: this.transferencia_concepto,
          transferReference: this.transferencia_referencia,
          transferBank: this.transferencia_banco,
          tarjetNumber: this.tarjeta_numero,
          tarjetExp: this.dateVenc,
          gasolina: this.gasolina,
          dailyPrice: this.tarifa_diaria,
          iva: this.tarifa_iva,
          km: parseInt(this.kilometraje),
          deposit: this.garantia,
          licencia: this.licencia,
          vencimiento: this.vencimiento,
          ciudad: this.ciudad,
          state: this.state,
          depositTarjetAuth: this.depositTarjetAuth,
          depositTarjetExp: this.dateVencDeposit,
          depositTarjetNumber: this.depositTarjetNumber,
          pais: this.pais,
          direccion: this.direccion,
          depositTarjet: this.deposit_tarjet,
          depositCash: this.deposit_cash,
          depositTransfer: this.deposit_transfer
        }).then(response => {
          if (response.data.data.updateContrato.success) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "success",
              title: "Operación realizada con éxito"
            });
            this.Close_nuevo();
            this.loadDatas(true);
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: response.data.data.createReservaAdmin.error
            });
          }
        }).catch(error => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: error
          });
        }).finally(() => {
          this.saveChanges = false;
        });
      } else {
        this.error_vacios = true
      }
    },
    Crear() {
      let validacion = true
      if (parseFloat(this.deposit_tarjet) + parseFloat(this.deposit_cash) + parseFloat(this.deposit_transfer) !== this.total) {
        this.error_monto_total = true
        return false
      }
      this.error_monto_total = false
      if (this.depositTarjetExp === null || this.Diferencia_Fechas(Date.now(), this.depositTarjetExp) < 1) {
        validacion = false
        this.errordepositTarjetExp = true
      }
      if (this.depositTarjetAuth === "") {
        validacion = false
        this.errordepositTarjetAuth = true
      }
      if (this.depositTarjetNumber === "") {
        validacion = false
        this.errordepositTarjetNumber = true
      }
      if (validacion) {
        this.errordepositTarjetExp = false
        this.errordepositTarjetAuth = false
        this.errordepositTarjetNumber = false
        if (this.accion === 'nuevo') {
          this.saveChanges = true;
          createContract({
            nombre: this.nombre,
            apellidos: this.apellidos,
            telefono: this.telefono,
            codigoTelefono: this.codigo,
            correo: this.email,
            comentario: this.comentarios,
            sucursalRecogida: this.sucursal_recogida,
            sucursalEntrega: this.sucursal_entrega,
            fechaSalida: this.fecha_salida,
            fechaRegreso: this.fecha_regreso,
            proteccion: this.proteccion !== null ? this.proteccion : 0,
            metodoPago: this.getPaymentMethod(),
            procedencia: this.procedencia,
            total: this.total,
            servicios: this.servicios_extras,
            dias: this.dias,
            vehiculo: this.vehiculo,
            reserva: this.reserva_id,
            transferConcept: this.transferencia_concepto,
            transferReference: this.transferencia_referencia,
            transferBank: this.transferencia_banco,
            tarjetNumber: this.tarjeta_numero,
            tarjetExp: this.dateVenc,
            gasolina: this.gasolina,
            dailyPrice: this.tarifa_diaria,
            iva: this.tarifa_iva,
            creator: this.$store.state.userid,
            km: parseInt(this.kilometraje),
            extraDriver: this.drivers_id,
            deposit: this.garantia,
            licencia: this.licencia,
            vencimiento: this.vencimiento,
            ciudad: this.ciudad,
            state: this.state,
            depositTarjetAuth: this.depositTarjetAuth,
            depositTarjetExp: this.dateVencDeposit,
            depositTarjetNumber: this.depositTarjetNumber,
            pais: this.pais,
            direccion: this.direccion,
            depositTarjet: this.deposit_tarjet,
            depositCash: this.deposit_cash,
            depositTransfer: this.deposit_transfer
          }).then(response => {
            if (response.data.data.createContrato.success) {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                customClass: {
                  popup: 'bg-white',
                  title: 'text-dark'
                },
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "success",
                title: "Operación realizada con éxito"
              });
              this.Close_nuevo();
              this.loadDatas(true);
            } else {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                customClass: {
                  popup: 'bg-white',
                  title: 'text-dark'
                },
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "error",
                title: response.data.data.createReservaAdmin.error
              });
            }
          }).catch(error => {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: error
            });
          }).finally(() => {
            this.saveChanges = false;
          });
        } else {
          this.error_vacios = true
        }
      }
    },

    calcularDeuda() {
      if (this.monto_horas_extras > 0) {
        this.monto_horas_extras = this.monto_horas_extras + this.monto_damage
        this.efectivo = this.monto_horas_extras + this.monto_damage
      } else if (this.monto_reembolso > 0) {
        this.monto_horas_extras = this.monto_reembolso - this.monto_damage
      } else {
        this.monto_horas_extras = this.monto_damage
      }
    },
    loadDamages(id) {
      this.save_damage = false
      this.damage_description = ""
      this.damage_price = 0
      this.monto_damage = 0
      getDamagesByContract({id: decodeBase64(id)}).then(response => {
        this.damages = response.data.data.getDamagesByContract
        this.damages.forEach(element => {
          this.monto_damage = this.monto_damage + parseFloat(element.price)
        })
        this.calcularDeuda()
      }).catch(error => {
        console.log(error)
      })
    },
    addDamage() {
      this.save_damage = true
      if (this.damage_description !== "" && this.damage_price > 0) {
        this.monto_horas_extras = this.monto_horas_extras + parseFloat(this.damage_price)
        this.efectivo = this.efectivo + parseFloat(this.damage_price)
        addDamage({
          id: this.contract_id,
          damage: this.damage_description,
          price: this.damage_price
        }).then(response => {
          if (response.data.data.addDamage.success) {
            this.loadDamages(this.contract_id)
          }
          this.save_damage = false
          this.damage_description = ""
          this.damage_price = 0
        })
      }
    },
    deleteDamage(item) {
      this.save_damage = true
      this.monto_horas_extras = this.monto_horas_extras + parseFloat(item.price)
      this.efectivo = this.efectivo + parseFloat(item.price)
      deleteDamage({id: item.id}).then(response => {
        if (response.data.data.deleteDamage.success) {
          this.loadDamages(this.contract_id)
        }
        this.save_damage = false
        this.damage_description = ""
        this.damage_price = 0
      })
    },
    openEstadoModal(item) {
      this.contract_id = decodeBase64(item.id)
      this.cargando = true
      this.ventana = 0;
      this.currentStep = 1;
      this.loadDamages(this.contract_id)
      searchContractByIdForDetails(item.id).then(response => {
        this.item = response.data.data.searchContractById;
        this.item.id = decodeBase64(item.id);
        this.horas_extras_entrega = this.Diferencia_Horas(this.item.fechaRegreso, new Date());
        if (this.horas_extras_entrega > 3) {
          this.dias_extras = Math.ceil(this.horas_extras_entrega / 24)
          this.monto_horas_extras = this.dias_extras * parseFloat(this.item.dailyPrice)
          this.horas_extras_entrega = 0
        } else if (this.horas_extras_entrega < 3 && this.horas_extras_entrega > 0) {
          this.monto_horas_extras = this.horas_extras_entrega * (parseFloat(this.item.dailyPrice) / 4)
        } else if (this.horas_extras_entrega < 0) {
          this.dias_reembolso = Math.ceil((this.horas_extras_entrega * -1) / 24) <= this.item.dias ? Math.ceil((this.horas_extras_entrega * -1) / 24) : parseInt(this.item.dias)
          if (this.dias_reembolso > 0) {
            let monto_proteccion = 0
            if (this.item.proteccion) {
              monto_proteccion = parseFloat(this.item.proteccion.tarifa) * this.dias_reembolso
            }
            let monto_servicios_extras = 0
            this.item.serviciosContratoSet.edges.forEach(service => {
              if (service.node.servicio.diario) {
                monto_servicios_extras = monto_servicios_extras * (parseFloat(service.node.servicio.precio) * this.dias_reembolso)
              }
            })
            let monto_diario = this.dias_reembolso * parseFloat(this.item.dailyPrice)
            this.monto_reembolso = monto_proteccion + monto_servicios_extras + monto_diario
          }
        }
        this.calcularDeuda()
        this.Estado_Modal = true;
      }).catch(err => console.log(err)).finally(() => {
        this.cargando = false;
        this.fecha_entrega = new Date();
      });
    },
    async generateComprobantePDF() {
      this.cargando = true
      this.Comprobante_Modal = true
      setTimeout(() => {
        const contentToExport = document.getElementById('comprobante-pdf');
        const options = {
          margin: [5, 5, 5, 5],
          filename: "Comprobante con " + this.item.cliente.nombre + " " + this.item.cliente.apellidos + ".pdf",
          image: {type: "jpeg", quality: 0.98},
          html2canvas: {scale: 2},
          jsPDF: {unit: "mm", format: "a4", orientation: "portrait"},
          pagebreak: {mode: 'avoid-all', before: '#page2el'}
        };
        html2pdf()
            .from(contentToExport)
            .set(options)
            .output("blob", "comprobante.pdf")
            .then(dataUri => {
              const blob = new Blob([dataUri], {type: "application/pdf"});
              const url = URL.createObjectURL(blob);
              window.open(url, "_blank");
            });
        this.close_Comprobante();
        this.closeEstadoModal();
      }, 50);
    },
    CloseContrato() {
      var validacion = true
      if (this.kilometraje_entrega <= 0 || this.kilometraje_entrega === '') {
        validacion = false
        this.error_kilometraje_entrega = true;
      }
      if (this.fecha_entrega === null) {
        validacion = false
        this.error_fecha_entrega = true
      }
      if (validacion) {
        this.saveChanges = true;
        closeContract({
          contrato: this.item.id,
          horasExtras: this.horas_extras_entrega,
          dias: this.dias,
          kilometraje: this.kilometraje_entrega,
          litros: this.combustible_tanque_entrega,
          metodoPago: this.metodo_pago_entrega,
          montoPagar: this.monto_horas_extras,
          montoDamage: this.monto_damage,
          fecha: this.fecha_entrega,
          reembolso_monto: this.monto_reembolso,
          reembolso_dias: this.dias_reembolso
        }).then(response => {
          if (response.data.data.closeContrato.success) {
            this.generateComprobantePDF()
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "success",
              title: "Operación realizada con éxito"
            });
            this.loadDatas();
          }
        }).catch(err => console.log(err)).finally(() => {
          this.saveChanges = false;
        })
      } else {
        this.error_vacios = true;
      }
    },

    addExtraDriver() {
      if (this.nombre_extra === "" || this.apellidos_extra === "" || this.tipo_licencia_extra === "" ||
          this.num_licencia_extra === "" || this.vencimiento_licencia_extra === null) {
        this.error_vacios = true
        return false
      }
      this.error_vacios = false
      let extra_drivers = {
        first_name: "",
        last_name: "",
        license_type: "",
        license_number: "",
        license_expire: null
      }
      extra_drivers.first_name = this.nombre_extra
      extra_drivers.last_name = this.apellidos_extra
      extra_drivers.license_number = this.num_licencia_extra
      extra_drivers.license_expire = this.vencimiento_licencia_extra
      extra_drivers.license_type = this.tipo_licencia_extra
      this.drivers.push(extra_drivers)
      this.nombre_extra = ""
      this.apellidos_extra = ""
      this.num_licencia_extra = ""
      this.vencimiento_licencia_extra = null
      this.tipo_licencia_extra = ""
    },
    formatearFechaLicense(fecha) {
      moment.locale("es");
      return moment(fecha).format("DD/MM/YYYY");
    },
    deleteDriver(number) {
      this.drivers = this.drivers.filter(item => item.license_number !== number);
    },
    getPaymentMethod() {
      const selectedCount = Object.values(this.selectedPayments).filter(Boolean).length;
      if (selectedCount === 2) {
        return 'Mixto';
      } else if (selectedCount === 1) {
        if (this.selectedPayments.efectivo) {
          return 'Efectivo';
        } else if (this.selectedPayments.tarjeta) {
          return 'Tarjeta';
        } else if (this.selectedPayments.transferencia) {
          return 'Transferencia';
        }
      }

      return 'Ninguno';
    },


    getImageUrl(imagen) {
      return srac_url_image + imagen;
    },
    loadPhotoContract() {
      getContractFilesAdmin({id: this.id_to_upload}).then(response => {
        this.photos = response.data.data.getContractFilesAdmin
        this.Photo_Modal = true
        this.disabled_button = false
      })
    },
    uploadFiles(id) {
      this.id_to_upload = decodeBase64(id)
      this.loadPhotoContract()
    },
    close_Photo() {
      this.id_to_upload = 0
      this.photo_car = ""
      this.Photo_Modal = false
    },
    onPhoto(event) {
      const file = event.target.files[0];
      this.preview_photo = URL.createObjectURL(file);
      const reader = new FileReader();
      reader.onload = () => {
        this.photo_car = reader.result
      }
      reader.readAsDataURL(file);
    },
    Preview_Photo(item) {
      this.preview_photo = this.getImageUrl(item.photo)
    },
    Save_Photo() {
      this.disabled_button = true
      if (this.photo_car !== "") {
        uploadPhotoContract({
          contract: this.id_to_upload,
          photo: this.photo_car
        }).then(response => {
          if (response.data.data.uploadPhotoContract.success) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white z-index5',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "success",
              title: "Operación realizada con éxito"
            });
            this.loadPhotoContract()
            this.preview_photo = null
            this.photo_car = ""
            this.photo_input = ""
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white z-index5',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: response.data.data.uploadPhotoContract.error
            });
            this.disabled_button = false
          }
        })
      } else {
        this.error_vacios = true
        this.disabled_button = false
      }

    },
    DeletePhoto(id) {
      this.disabled_button = true
      deletePhotoContract({
        id: id
      }).then(response => {
        if (response.data.data.deletePhotoContract.success) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white z-index5',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "warning",
            title: "Imagen eliminada"
          });
          this.loadPhotoContract()
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white z-index5',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: response.data.data.deletePhotoContract.error
          });
          this.disabled_button = false
        }
      })
    },

    getMarcas() {
      if (this.fecha_salida !== null && this.fecha_regreso !== null && this.categoria !== null && this.sucursal_recogida !== null) {
        getAllMarcaForContract({
          category: decodeBase64(this.categoria),
          fechaSalida: this.fecha_salida,
          fechaRegreso: this.fecha_regreso,
          ciudad: decodeBase64(this.sucursal_recogida)
        }).then(resp => {
          this.marcasByCategoria = resp.data.data.marcasByCategoria
        }).catch(err => console.log(err));
      }
    },
    getCars() {
      if (this.fecha_salida !== null && this.fecha_regreso !== null && this.categoria !== null && this.sucursal_recogida !== null) {
        getAllAutoForContract({
          category: decodeBase64(this.categoria),
          fechaSalida: this.fecha_salida,
          fechaRegreso: this.fecha_regreso,
          ciudad: decodeBase64(this.sucursal_recogida)
        }).then(resp => {
          this.vehiculosByName = resp.data.data.vehiculosDisponibles
        }).catch(err => console.log(err));
      }
    },
    getReservedCar() {
      if (this.reserva !== null) {
        getCarReserved({reserva: decodeBase64(this.reserva.id)}).then(response => {
          this.marca_reservada.name = response.data.data.carReserved.modelo.marca.name
          this.marca_reservada.id = response.data.data.carReserved.modelo.marca.id
          this.car_reserved.no_econ = response.data.data.carReserved.noEconomico
          this.car_reserved.id = response.data.data.carReserved.id
        })
      }
    },
    formatCardNumber(cardNumber) {
      if (cardNumber && cardNumber.length >= 15) {
        return 'xxxx xxxx xxxx x' + cardNumber.slice(-3);
      }
      return cardNumber;
    },
    getDay(fecha) {
      if (fecha) {
        moment.locale("es");
        return moment(fecha).format("DD");
      } else {
        return ""
      }
    },
    getMonth(fecha) {
      if (fecha) {
        moment.locale("es");
        return moment(fecha).format("MM");
      } else {
        return ""
      }
    },
    getYear(fecha) {
      if (fecha) {
        moment.locale("es");
        return moment(fecha).format("YYYY");
      } else {
        return ""
      }
    },
    getExpireDate(fecha) {
      if (fecha) {
        moment.locale("es");
        return moment(fecha).format("MM/YYYY");
      } else {
        return ""
      }
    },
    async generateReportPDF(id) {
      this.id = id
      this.accion = "print"
      this.cargando = true
      searchContractByIdForDetails(id).then(response => {
        this.item = response.data.data.searchContractById
        isHightSeason(this.item.fechaSalida).then(response => {
          this.is_higth_season = response.data.data.isHighSeason
          this.total_diario = parseInt(this.Diferencia_Fechas(this.item.fechaSalida, this.item.fechaRegreso)) * parseFloat(this.is_higth_season ? this.item.vehiculo.categoria.tarifaAlta : this.item.vehiculo.categoria.tarifaBaja)
        })
        extraDriverByContract(decodeBase64(id)).then(resp => {
          this.drivers_info = resp.data.data.extraDriverByContract
          if (this.item.proteccion) {
            getProtectionsById(this.item.proteccion.id).then(response => {
              this.all_protecciones = response.data.data.proteccionesById
            })
          }
          if (parseInt(this.item.iva) === 0) {
            const subtotal = parseFloat(this.item.total) / ((parseFloat(this.item.contratoSucursalRecogida.ciudad.iva) / 100) + 1)
            this.iva_pdf = parseFloat(this.item.total) - parseFloat(subtotal)
          } else {
            this.iva_pdf = parseFloat(this.item.iva)
          }
          this.Print_Modal = true
          setTimeout(() => {
            console.log(this.drivers_info)
            let base64Data = ""
            const contentToExport = document.getElementById('contract-pdf');
            const options = {
              margin: [5, 5, 5, 5],
              filename: "Contrato con " + this.item.cliente.nombre + " " + this.item.cliente.apellidos + ".pdf",
              image: {type: "jpeg", quality: 0.98},
              html2canvas: {scale: 2},
              jsPDF: {unit: "mm", format: "a4", orientation: "portrait"},
              pagebreak: {mode: 'avoid-all', before: '#page2'}
            };
            html2pdf()
                .from(contentToExport)
                .set(options)
                .output("blob", "contrato.pdf")
                .then(dataUri => {
                  const blob = new Blob([dataUri], {type: "application/pdf"});
                  const url = URL.createObjectURL(blob);
                  const reader = new FileReader();
                  reader.readAsDataURL(blob);
                  reader.onloadend = () => {
                    base64Data = reader.result
                    savePdfContract({
                      contrato: decodeBase64(this.item.id),
                      pdf: base64Data
                    })
                  }
                  window.open(url, "_blank");
                });
            this.close_Print();
          }, 50);
        })
      })
    },
    close_Print() {
      this.cargando = false
      this.Print_Modal = false
      this.id = 0
      this.garantia = 0
      this.accion = ""
      this.all_serivicios_extras = []
    },
    openNuevo() {
      this.ventana = 0;
      this.currentStep = 1;
      this.Nuevo_Modal = true;
      this.getBranchsOffice();
      this.getAllClients();
      this.getCategories();
      this.getProtections();
      this.getServices();
      this.getOrigins();
    },
    getBranchsOffice() {
      getBranchOfficeForContract().then(response => {
        this.sucursalesApp = response.data.data.sucursalesApp
      }).catch(err => console.log(err));
    },
    getAllClients() {
      getAllClientsForContract().then(response => {
        this.clientesByName = response.data.data.clientesByName;
      }).catch(err => console.log(err));
    },
    getCategories() {
      getCategoriesForContract().then(response => {
        this.categoriasByName = response.data.data.categoriasByName;
      }).catch(err => console.log(err));
    },
    getProtections() {
      getProtectionsForContract().then(response => {
        this.proteccionesApp = response.data.data.proteccionesApp;
      }).catch(err => console.log(err));
    },
    getServices() {
      getServicesForContract().then(response => {
        this.serviciosExtrasApp = response.data.data.serviciosExtrasApp;
      }).catch(err => console.log(err));
    },
    getOrigins() {
      getOriginsForContract().then(response => {
        this.procedenciaByName = response.data.data.procedenciaByName;
        this.procedenciaByName.forEach(item => {
          if (item.nombre.includes("Directas") && this.procedencia === 0) {
            this.procedencia = item.id
          }
        })
      }).catch(err => console.log(err));
    },
    close_Comprobante() {
      this.contract_id = 0
      this.item = null
      this.combustible_tanque_entrega = 0
      this.kilometraje_entrega = 0
      this.cargando = false
      this.Comprobante_Modal = false
    },
    closeEstadoModal() {
      this.sin_pago = true
      this.Estado_Modal = false;
    },
    Details(id) {
      this.cargando = true
      this.loadDamages(decodeBase64(id))
      searchContractByIdForDetails(id).then(response => {
        this.item = response.data.data.searchContractById;
        this.total_diario = parseInt(this.item.dias) * parseFloat(this.item.dailyPrice)
        this.subtotal = (parseInt(this.item.dias) * parseFloat(this.item.dailyPrice)) + parseFloat(this.item.totalServicios) + parseFloat(this.item.totalProteccion) + parseFloat(this.item.totalDropoff)
        getIvaForSuc(decodeBase64(this.item.contratoSucursalRecogida.id)).then(resp => {
          this.details_porc_iva = resp.data.data.ivaBySucursal
          const contrato_total = this.total_diario + parseFloat(this.item.totalServicios) + parseFloat(this.item.totalProteccion) + parseFloat(this.item.totalDropoff)
          this.details_iva = contrato_total * parseFloat(this.details_porc_iva / 100)
        })
        this.Details_Modal = true;
      }).catch(err => console.log(err)).finally(() => {
        this.cargando = false;
      });
    },
    numeroFormateado(numero) {
      const num = parseFloat(numero).toFixed(2);
      return parseFloat(num).toLocaleString("en-US", {minimumFractionDigits: 2});
    },
    close_Details() {
      this.pagina = 0
      this.details_iva = 0
      this.details_porc_iva = 0
      this.Details_Modal = false
    },
    Siguiente_dos() {
      var validacion = true
      if (this.sucursal_recogida === 0) {
        validacion = false
        this.error_sucursal_recogida = true
      }
      if (this.sucursal_entrega === 0) {
        validacion = false
        this.error_sucursal_entrega = true
      }
      if (this.categoria === null) {
        validacion = false
        this.error_categoria = true
      }
      if (this.accion === "nuevo") {
        if (this.fecha_salida === "" || this.Diferencia_Fechas(Date.now(), this.fecha_salida) < 0) {
          this.error_fecha_salida = true
          validacion = false
        }
      } else {
        if (this.fecha_salida === "") {
          this.error_fecha_salida = true
          validacion = false
        }
      }

      if (this.fecha_regreso === "" || this.Diferencia_Fechas(this.fecha_salida, this.fecha_regreso) < 1) {
        this.error_fecha_regreso = true
        validacion = false
      }
      if (validacion) {
        this.servicios_extras.forEach(value => {
          serviceByIdForContract(value).then(resp => {
            const serv = resp.data.data.servicioById;
            if (serv.name.toLowerCase().includes('conductor')) {
              this.conductor_extra = true;
            }
          });
        })
        this.Calcular_Dropoff()
        this.Calcular_Extras()
        this.ventana = 1
        this.currentStep = 2
        this.error_vacios = false
      } else {
        this.error_vacios = true
      }
    },
    Siguiente_tres() {
      var validacion = true
      if (this.vehiculo === 0) {
        validacion = false
        this.error_sucursal_recogida = true
      }
      if (this.conductor_extra && this.drivers.length > 0) {
        this.drivers.forEach(item => {
          createExtraDriver({
            nombre: item.first_name,
            apellidos: item.last_name,
            clase: item.license_type,
            numero: item.license_number,
            vencimiento: item.license_expire
          }).then(response => {
            this.drivers_id.push(response.data.data.createExtraDriver.driver)
          }).catch(error => {
            console.log(error)
          })
        })
      }
      if (validacion) {
        console.log(this.drivers_id)
        this.ventana = 2
        this.currentStep = 3
        this.error_vacios = false
      } else {
        this.error_vacios = true
      }
    },
    Regresar() {
      this.ventana = this.ventana - 1
      this.currentStep = this.currentStep - 1
    },
    Siguiente_cuatro() {
      let validacion = true
      if (this.nombre === "") {
        this.error_nombre = true
        validacion = false
      }
      if (this.apellidos === "") {
        this.error_apellidos = true
        validacion = false
      }
      if (this.telefono === "") {
        this.error_telefono = true
        validacion = false
      }
      if (this.email === "" || /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(this.email) === false) {
        this.error_email = true
        validacion = false
      }
      if (validacion) {
        this.error_vacios = false
        this.ventana = 3
        this.currentStep = 4
      } else {
        this.error_vacios = true
        return false
      }
    },
    Quitar_extra(id) {
      this.servicios_extras = this.servicios_extras.filter(item => item !== parseInt(id));
      serviceByIdForContract(id).then(resp => {
        const serv = resp.data.data.servicioById;
        if (serv.name.toLowerCase().includes('conductor')) {
          this.conductor_extra = false;
        }
      }).catch(err => console.log(err));
      if (this.servicios_extras.length > 0) {
        getServiceFee({
          servicios: this.servicios_extras,
          dias: this.dias
        }).then(response => {
          this.tarifa_servicios = response.data.data.tarifaServicios;
          this.Calcular_Total();
          this.servicios = 0;
        }).catch(err => console.log(err));
      } else {
        this.tarifa_servicios = 0
      }
    },
    Diferencia_Fechas(fecha1, fecha2) {
      const diffInHours = moment(fecha2).diff(fecha1, 'hours');
      return Math.ceil(diffInHours / 24);
    },
    Diferencia_Horas(fecha1, fecha2) {
      return moment(fecha2).diff(fecha1, 'hours');
    },
    Calcular_Dropoff() {
      if (this.sucursal_recogida !== 0 && this.sucursal_entrega !== 0) {
        dropOffAction({
          sucursal1: decodeBase64(this.sucursal_recogida),
          sucursal2: decodeBase64(this.sucursal_entrega)
        }).then(response => {
          this.tarifa_dropoff = response.data.data.tarifaDropoff;
          this.Calcular_Total();
        }).catch(err => console.log(err));
      }
    },
    Calcular_Total() {
      this.total = parseFloat(this.tarifa_dropoff) + parseFloat(this.tarifa_servicios) + parseFloat(this.tarifa_proteccion) + (parseFloat(this.tarifa_diaria) * parseInt(this.dias))
      this.sub_total = parseFloat(this.tarifa_dropoff) + parseFloat(this.tarifa_servicios) + parseFloat(this.tarifa_proteccion) + (parseFloat(this.tarifa_diaria) * parseInt(this.dias))
      if (this.reserva) {
        if (this.reserva.promocion) {
          this.total = this.total - parseFloat(this.reserva.totalPromocion)
        }
      }
      this.tarifa_iva = this.total * parseFloat(this.porciento_iva) / 100
      this.total = this.total + this.tarifa_iva
      const result = parseFloat(this.total) - parseFloat(this.monto_pagado)
      this.pago_pendiente = result > 0 ? result : 0
    },
    Calcular_Extras() {
      if (this.servicios_extras.length > 0) {
        getServiceFee({
          servicios: this.servicios_extras,
          dias: this.dias
        }).then(response => {
          this.tarifa_servicios = response.data.data.tarifaServicios;
          this.Calcular_Total();
          this.servicios = 0;
        })
      }
    },
    autocomplete() {
      this.autocompleteItems = this.clientesByName.filter(item =>
          item.user && item.user.email.toLowerCase().includes(this.email.toLowerCase())
      );
      this.showSubmenu = this.email !== "";
    },
    selectItem(item) {
      this.showSubmenu = false
      this.deshabilitado = true;
      this.nombre = item.nombre !== "" ? item.nombre : "";
      this.apellidos = item.apellidos !== "" ? item.apellidos : "";
      this.email = item.user.email;
      this.telefono = item.contactoSet.edges.length > 0 ? item.contactoSet.edges[0].node.telefono : "";
      this.codigo = item.contactoSet.edges.length > 0 ? item.contactoSet.edges[0].node.codigo : "";
      this.state = item.direccionSet.edges.length > 0 ? item.direccionSet.edges[0].node.estado : "";
      this.ciudad = item.direccionSet.edges.length > 0 ? item.direccionSet.edges[0].node.ciudad : "";
      this.pais = item.direccionSet.edges.length > 0 ? item.direccionSet.edges[0].node.pais : "";
      this.direccion = item.direccionSet.edges.length > 0 ? item.direccionSet.edges[0].node.direccion : "";
      this.licencia = item.licenciaSet.edges.length > 0 ? item.licenciaSet.edges[0].node.numero : "";
      this.vencimiento = item.licenciaSet.edges.length > 0 ? new Date(item.licenciaSet.edges[0].node.vencimiento) : "";
      this.tarjeta_numero = item.tarjetaSet.edges.length > 0 ? item.tarjetaSet.edges[0].node.numero : "";
      this.fecha_tarjeta = item.tarjetaSet.edges.length > 0 ? item.tarjetaSet.edges[0].node.vencimiento : "";


      this.showSubmenu = false;
    },
    cancelarContrato(id) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "Esta acción no se puede deshacer",
        showDenyButton: true,
        showCancelButton: true,
        reverseButtons: true,
        showConfirmButton: false,
        customClass: {
          popup: 'bg-white',
          title: 'text-dark'
        },
        cancelButtonText: "Cancelar",
        denyButtonText: "Sí, cancelar el contrato!"
      }).then(async (result) => {
        if (result.isDenied) {
          cancelContract(decodeBase64(id)).then(resp => {
            if (resp.data.data.cancelContrato.success) {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                customClass: {
                  popup: 'bg-white',
                  title: 'text-dark'
                },
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "error",
                title: "Contrato cancelado"
              });
              this.loadDatas();
            }
          }).catch(err => console.log(err));
        }
      });
    },
    formatearFecha(fecha) {
      moment.locale("es");
      return moment(fecha).format("DD/MM/YYYY HH:mm a");
    },
    formatearFechaExpire(fecha) {
      moment.locale("es");
      return moment(fecha).format("DD/MM/YYYY");
    },

    generateUrlDocument(url) {
      return `${process.env.VUE_APP_URL_IMAGE}${url}`;
    },

    loadDatas(init = false) {
      this.cargando = true
      searchContractsPromotions(this.search).then(resp => {
        this.items = resp.data.data.searchContractsPromotions.edges.map(value => {
          return {
            ...value.node,
            loadingInvoice: false
          };
        });
      }).catch(err => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    nextPage() {
      this.loading = true;
      searchNextContracts({
        name: this.search,
        after: this.endCursor,
        first: this.cant,
        estado: ""
      }).then(resp => {
        this.lastEndCursor = "";
        this.lastStartCursor = this.endCursor;
        this.hasNextPage = resp.data.data.searchContracts.pageInfo.hasNextPage;
        this.hasPreviousPage = true;
        this.startCursor = resp.data.data.searchContracts.pageInfo.startCursor;
        this.endCursor = resp.data.data.searchContracts.pageInfo.endCursor;
        this.items = resp.data.data.searchContracts.edges.map(value => {
          return {
            ...value.node,
            loadingInvoice: false
          };
        });
      }).catch((err) => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    prevPage() {
      this.loading = true;
      searchPrevContracts({
        name: this.search,
        before: this.startCursor,
        first: this.cant,
        estado: ""
      }).then(resp => {
        this.lastEndCursor = this.startCursor;
        this.lastStartCursor = "";
        this.hasNextPage = true;
        this.hasPreviousPage = resp.data.data.searchContracts.pageInfo.hasPreviousPage;
        this.startCursor = resp.data.data.searchContracts.pageInfo.startCursor;
        this.endCursor = resp.data.data.searchContracts.pageInfo.endCursor;
        this.items = resp.data.data.searchContracts.edges.map(value => {
          return {
            ...value.node,
            loadingInvoice: false
          };
        });
      }).catch((err) => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    openDialogInvoice(item) {
      item.loadingInvoice = true;
      clientConfigInvoices(item.id).then(resp => {
        if (resp.data.data.clientConfigInvoices) {
          this.objInvoice = Object.assign({}, auxInvoice);
          this.itemInvoice = {...item};
          this.objInvoice.contract = this.itemInvoice.id;
          this.autocompleteWithClient();
          this.loadingIssuers = true;
          searchIssuersConfigActive().then(resp => {
            this.issuers = resp.data.data.searchIssuers.edges.map(e => e.node);
            if (this.issuers.length === 1) {
              this.objInvoice.issuer = this.issuers[0].id;
            }
          }).catch(err => console.log(err)).finally(() => {
            this.loadingIssuers = false;
          });
          this.dialogInvoice = true;
        } else {
          this.toast.fire({
            icon: "error",
            title: "Información de facturación incompleta"
          });
          this.$router.push({
            name: "Clientes",
            query: {
              "client": item.cliente.id,
              "view": this.$route.name,
              "contract": item.id
            }
          });
        }
      }).catch(err => console.log(err)).finally(() => {
        item.loadingInvoice = false;
      });
    },
    createInvoiceAction() {
      this.loadingInvoice = true;
      this.objInvoice.cfdiCost = this.objInvoice.cfdiCost.toString();
      this.objInvoice.postal = parseInt(this.objInvoice.postal);
      createInvoice(this.objInvoice).then(resp => {
        const {info, error} = resp.data.data.createInvoice;
        if (info) {
          this.toast.fire({
            icon: "success",
            title: "Factura generada con éxito"
          });
          this.dialogInvoice = false;
          this.loadDatas();
        } else {
          this.errorInvoiceMsg = error;
          this.errorInvoice = true;
          this.toast.fire({
            icon: "error",
            title: error
          });
        }
      }).catch(err => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(() => {
        this.loadingInvoice = false;
      })
    },
    autocompleteWithClient() {
      this.loadingAutcompleteClient = true;
      getDataAutocompleteClient(this.itemInvoice.id).then(resp => {
        const {cliente} = resp.data.data.searchContractById;
        this.objInvoice.client = `${cliente.nombre} ${cliente.apellidos}`;
        this.objInvoice.rfc = cliente.rfc !== null ? cliente.rfc : '';
        this.objInvoice.colonia = cliente.colonia !== null ? cliente.colonia : '';
        this.objInvoice.socialReason = cliente.socialReason !== null ? cliente.socialReason : '';
        this.objInvoice.fiscalAddress = cliente.fiscalAddress !== null ? cliente.fiscalAddress : '';
        this.objInvoice.municipality = cliente.municipality !== null ? cliente.municipality : '';
        this.objInvoice.state = cliente.stateInvoice !== null ? cliente.stateInvoice : '';
        this.objInvoice.person = cliente.person;
        this.objInvoice.email = cliente.emailInvoice;
        this.objInvoice.postal = cliente.postalCodeInvoice;
      }).catch(err => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(() => {
        this.loadingAutcompleteClient = false;
      });
    },
  },
  mounted() {
    this.cargando = true;
    this.loadDatas(true);
    if (this.$route.query["contract"]) {
      this.openDialogInvoice({
        id: this.$route.query["contract"],
      });
    }
  },
  computed: {
    dateVenc() {
      if (!this.fecha_tarjeta) {
        return null
      }
      if (typeof (this.fecha_tarjeta) === "object") {
        if (this.fecha_tarjeta.year) {
          d = new Date(this.fecha_tarjeta.year, this.fecha_tarjeta.month - 1);
        } else {
          d = this.fecha_tarjeta
        }
        const month = d.getMonth() < 9 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
        const day = d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`
        return `${d.getFullYear()}-${month}-${day}`;
      }
      let d = null;
      if (typeof (this.fecha_tarjeta) === 'string') {
        d = new Date(this.fecha_tarjeta);
      } else {
        d = new Date(this.fecha_tarjeta.year, this.fecha_tarjeta.month - 1);
      }
      const month = d.getMonth() < 9 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
      const day = d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`
      return `${d.getFullYear()}-${month}-${day}`;
    },
    dateVencDeposit() {
      if (!this.depositTarjetExp) {
        return null
      }
      if (typeof (this.depositTarjetExp) === "object") {
        if (this.depositTarjetExp.year) {
          d = new Date(this.depositTarjetExp.year, this.depositTarjetExp.month - 1);
        } else {
          d = this.depositTarjetExp
        }
        const month = d.getMonth() < 9 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
        const day = d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`
        return `${d.getFullYear()}-${month}-${day}`;
      }
      let d = null;
      if (typeof (this.depositTarjetExp) === 'string') {
        d = new Date(this.depositTarjetExp);
      } else {
        d = new Date(this.depositTarjetExp.year, this.depositTarjetExp.month - 1);
      }
      const month = d.getMonth() < 9 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
      const day = d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`
      return `${d.getFullYear()}-${month}-${day}`;
    },
    isValidDataInvoice() {
      return this.objInvoice.person === '' || this.objInvoice.socialReason === '' ||
          this.objInvoice.socialReason.length === 0 || this.objInvoice.cfdiCost === '' ||
          this.objInvoice.cfdiCost.length === 0 || this.objInvoice.issuer === '' ||
          !(/^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(this.objInvoice.email)) ||
          this.objInvoice.rfc === '' || this.objInvoice.rfc.length !== 12 ||
          !(/[A-Z&Ñ]{3,4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]/.test(this.objInvoice.rfc))
          || this.objInvoice.client === '' || this.objInvoice.colonia === '' || this.objInvoice.state === ''
          || this.objInvoice.municipality === '' || this.objInvoice.postal === null || this.objInvoice.postal <= 10000
          || this.objInvoice.postal > 99999 || this.objInvoice.fiscalAddress === '';
    },
  }
};
</script>
<style scoped>
.z-index5 {
  z-index: 99999;
}

.img-list {
  object-fit: cover;
  height: 100px;
  width: 100px;
}

.text-contrato {
  color: #000;
  text-align: justify;
  font-size: 13px;
}

.modal-content-print {
  padding: 20px;
  width: 100%;
  margin-top: 99999px
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  padding: 20px;
  width: 70%;
}

.w-321 {
  width: 322px
}

@media (max-width: 767px) {
  .modal-content {
    border-radius: 15px;
    padding: 10px;
    width: 90%;
  }
}

.margin-firmas {
  margin-top: 80px !important;
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

.img-categoria {
  height: 50px;
  width: 50px;
  object-fit: cover;
}

.img-cliente {
  height: 250px;
  width: 250px;
  object-fit: cover;
}

.steps-container {
  display: flex;
  align-items: center;
}

.step {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  margin: 10px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px rgba(10, 10, 10, 0.60);
  box-shadow: 2px 2px 2px 2px rgba(10, 10, 10, 0.44);
}

.step-close {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  margin: 10px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.active {
  background-color: #1f1f1f;
  color: #fff;
}

.dp__theme_dark {
  --dp-background-color: #2b2a3f;
}

.selected-items {
  display: flex;
  margin-top: 10px;
}

.selected-item {
  background-color: lightgray;
  border: 1px solid gray;
  margin: 5px;
  padding: 5px 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.selected-item span {
  margin-right: 5px;
  color: #0d0c1d;
}

.remove-item {
  cursor: pointer;
  margin-left: 5px;
  color: #0d0c1d;
}

.icon-loading-custom {
  width: 22px !important;
  height: 22px !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.bold {
  font-weight: bold;
}
</style>
