<template>
  <div class="Cargando">
    <div v-show="loading" :class="['modal_cargando', { 'show': loading }]">
      <div class="spinner"></div>
    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
        class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25">
      <div class="d-sm-flex align-items-center">
        <form class="search-box position-relative">
          <input type="text" v-model="search" class="form-control shadow-none text-black rounded-0 border-0"
                 placeholder="Buscar promoción"/>
          <button class="bg-transparent text-primary transition p-0 border-0">
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
      </div>
      <div class="d-sm-flex align-items-center mt-10 mt-lg-0">
        <button @click="Nuevo"
                class="bg-primary position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block d-inline-block text-decoration-none">
          Nueva promoci&oacute;n
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
        </button>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
          <tr>
            <th scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0">
              C&oacute;digo
            </th>
            <th scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0">
              Nombre
            </th>
            <th scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0">
              Descripci&oacute;n
            </th>
            <th scope="col"
                class="text-uppercase fw-medium d-none d-md-table-cell shadow-none text-body-tertiary fs-13 pt-0 ps-0">
              Descuento
            </th>
            <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
              Acciones
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in promocionesByName" :key="item.id">
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.codigo }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.nombre }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary descripcion-column">
              {{ item.descripcion }}
            </td>
            <td class="shadow-none lh-1 fw-bold d-none d-md-table-cell text-body-tertiary">
              {{ item.porciento }} %
            </td>
            <td class="shadow-none lh-1 fw-bold text-body-tertiary">
              <div class="btn-group" role="group">
                <button v-if="item.isActive" data-bs-toggle="tooltip" style="padding: 0.8rem" data-bs-placement="top"
                        title="Desactivar promoción" data-container="body" data-animation="true"
                        class="btn text-success"
                        @click="Desactivar(item.id)">
                  <i class="flaticon-ban d-flex justify-content-center align-items-center"></i>
                </button>
                <button v-else data-bs-toggle="tooltip" data-bs-placement="top" style="padding: 0.8rem"
                        title="Activar promoción" data-container="body" data-animation="true" class="btn text-warning"
                        @click="Activar(item.id)">
                  <i class="flaticon-check-mark-1 d-flex justify-content-center align-items-center"></i>
                </button>
                <button data-bs-toggle="tooltip" @click="Actualizar(item)" style="padding: 0.8rem"
                        data-bs-placement="top" title="Editar promoción" data-container="body" data-animation="true"
                        class="btn text-info">
                  <i class="flaticon-pen d-flex justify-content-center align-items-center "></i>
                </button>
                <button data-bs-toggle="tooltip" @click="Details(item)" style="padding: 0.8rem"
                        data-bs-placement="top" title="Ver detalles de la promoción" data-container="body"
                        data-animation="true" class="btn text-secondary">
                  <i class="flaticon-eye d-flex justify-content-center align-items-center "></i>
                </button>
                <button data-bs-toggle="tooltip" @click="openPhoto(item)" style="padding: 0.8rem"
                        data-bs-placement="top" title="Subir fotos de la promoción" data-container="body"
                        data-animation="true" class="btn text-info">
                  <i class="flaticon-gallery d-flex justify-content-center align-items-center "></i>
                </button>
                <button data-bs-toggle="tooltip" @click="openDialogNotify(item)" style="padding: 0.8rem"
                        data-bs-placement="top" title="Notificar" data-container="body" data-animation="true"
                        class="btn text-info">
                  <i class="flaticon-bell d-flex justify-content-center align-items-center"></i>
                </button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="Nuevo text-dark">
    <div v-if="Nuevo_Modal" :class="['modal', { 'show': Nuevo_Modal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Nueva promoci&oacute;n
            <i @click="Close_nuevo" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Nombre</label>
            <input autofocus v-model="name" :class="error_name?'is-invalid':''"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Cantidad de usos</label>
            <input v-model="count" :class="error_count?'is-invalid':''"
                   class="form-control shadow-none rounded-0 text-black" type="number" min="0">
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">C&oacute;digo</label>
            <input v-model="codigo" :class="error_codigo?'is-invalid':''"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Porciento a descontar</label>
            <input v-model="porciento" type="number" :class="error_porciento?'is-invalid':''"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Fecha de inicio</label>
            <input v-model="fecha_inicio" type="date" :class="error_fecha_inicio?'is-invalid':''"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Fecha de fin</label>
            <input v-model="fecha_fin" type="date" :class="error_fecha_fin?'is-invalid':''"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Descripci&oacute;n</label>
            <textarea v-model="descripcion" :class="error_descripcion?'is-invalid':''"
                      class="form-control shadow-none rounded-0 text-black">
            </textarea>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <MazSwitch class="swtich-custom" v-model="sendNotify" name="notify">
              Notificar a clientes
            </MazSwitch>
          </div>
        </div>
        <div class="row mb-3">
          <div v-show="error_vacios" class="col-12 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
            <span class="d-flex align-items-center justify-content-center">
              <i class="flaticon-warning me-2 fs-15"></i>
              No pueden haber campos vac&iacute;os
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="Close_nuevo" class="btn btn-secondary d-block w-100" type="button">Cancelar</button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="Crear" class="btn btn-danger d-block w-100" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="Actualizar text-dark">
    <div v-if="Actualizar_Modal" :class="['modal', { 'show': Actualizar_Modal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Actualizar promoci&oacute;n
            <i @click="Close_update" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Nombre</label>
            <input autofocus v-model="name" :class="error_name?'is-invalid':''"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Cantidad de usos</label>
            <input autofocus v-model="count" :class="error_count?'is-invalid':''"
                   class="form-control shadow-none rounded-0 text-black" type="number" min="0">
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Código</label>
            <input v-model="codigo" :class="error_codigo?'is-invalid':''"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Porciento a descontar</label>
            <input v-model="porciento" type="number" :class="error_porciento?'is-invalid':''"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Fecha de inicio</label>
            <input v-model="fecha_inicio" type="date" :class="error_fecha_inicio?'is-invalid':''"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Fecha de fin</label>
            <input v-model="fecha_fin" type="date" :class="error_fecha_fin?'is-invalid':''"
                   class="form-control shadow-none rounded-0 text-black">
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Descripci&oacute;n</label>
            <textarea v-model="descripcion" :class="error_descripcion?'is-invalid':''"
                      class="form-control shadow-none rounded-0 text-black">
            </textarea>
          </div>
        </div>
        <div class="row mb-3">
          <div v-show="error_vacios" class="col-12 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
            <span class="d-flex align-items-center justify-content-center">
              <i class="flaticon-warning me-2 fs-15"></i>
              No pueden haber campos vac&iacute;os
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="Close_update" class="btn btn-secondary d-block w-100" type="button">Cancelar</button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="Editar" class="btn btn-danger d-block w-100" type="button">Actualizar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="Details text-dark">
    <div v-if="Details_Modal" :class="['modal', { 'show': Details_Modal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Detalles de la promoci&oacute;n
            <i @click="Close_details" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12 text-start">
            <h6>Nombre: {{ item.nombre }}</h6>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 text-start">
            <h6>Cantidad de usos: {{ item.count }}</h6>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 text-start">
            <h6>C&oacute;digo: {{ item.codigo }}</h6>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 text-start">
            <h6>Fecha de inicio: {{ item.fechaInicio }}</h6>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 text-start">
            <h6>Fecha de fin: {{ item.fechaFin }}</h6>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 text-start">
            <h6>Porciento de descuento: {{ item.porciento }} %</h6>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-lg-6 col-md-6 col-sm-12 text-start">
            <h6>Descripci&oacute;n:</h6>
            <h6> {{ item.descripcion }}</h6>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">

          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="Close_details" class="btn btn-danger d-block w-100" type="button">Cerrar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="Subir_foto text-dark">
    <div v-if="Photo_Modal" :class="['modal', { 'show': Photo_Modal }]">
      <div class="modal-content bg-white">
        <div class="row mb-3 d-flex align-items-center">
          <h4 class="text-start fw-bold">
            Subir o cambiar foto de la promoci&oacute;n
            <i @click="close_Photo" class="flaticon-close opacity-10 modal-icon fs-15"></i>
          </h4>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <label class="form-label ms-1">Foto de la promoci&oacute;n</label>
            <input :class="cliente_photo===null?'is-invalid':''" @change="onPhoto"
                   class="form-control shadow-none rounded-0 text-black" type="file">
          </div>
        </div>
        <div class="row mb-3 text-start">
          <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
            <img class="img-fluid img-cliente" :src="preview" alt="Imagen del cliente">
          </div>
        </div>
        <div class="row mb-3">
          <div v-show="error_vacios" class="col-12 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
            <span class="d-flex align-items-center justify-content-center">
              <i class="flaticon-warning me-2 fs-15"></i>
              Debe seleccionar una foto
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-6 d-flex justify-content-start mb-3">
            <button @click="close_Photo" class="btn btn-secondary d-block w-100" type="button">Cancelar</button>
          </div>
          <div class="col-md-12 col-lg-6 d-flex justify-content-end mb-3">
            <button @click="Subir_Photo" class="btn btn-danger d-block w-100" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>

    <div class="Nuevo text-dark">
      <div v-if="dialogNotify" :class="['modal', { 'show': dialogNotify }]">
        <div class="modal-content bg-white">
          <div class="row d-flex align-items-center">
            <h4 class="text-start fw-bold">
              Notificar
              <i @click="resetNewNotify" class="flaticon-close opacity-10 modal-icon fs-15"></i>
            </h4>
          </div>
          <div class="row text-start">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <label class="form-label ms-1" for="title">T&iacute;tulo</label>
              <input :disabled="saveChanges" :class="objNotify.title === '' ? 'is-invalid' : ''" id="title" autofocus
                     v-model="objNotify.title" class="form-control shadow-none rounded-0 text-black">
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
              <label class="form-label ms-1" for="message">Descripci&oacute;n</label>
              <textarea rows="3" :disabled="saveChanges" :class="objNotify.message === '' ? 'is-invalid' : ''"
                        id="message" v-model="objNotify.message"
                        class="form-control shadow-none rounded-0 text-black"></textarea>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 pt-3 pb-3 row">
              <div class="col-lg-10 col-md-9 col-sm-7">
                <MazSelect style="width: 100% !important" multiple :disabled="objNotify.allClients"
                           v-model="objNotify.clients" label="Clientes" :options="clients" search/>
              </div>
              <div class="col-lg-2 col-md-3 col-sm-5 pt-3">
                <MazSwitch class="swtich-custom" v-model="objNotify.allClients" name="Todos">
                  Todos
                </MazSwitch>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-lg-6 mt-1 d-flex justify-content-start">
              <button :disabled="saveChanges" @click="resetNewNotify" class="btn btn-secondary d-block w-100"
                      type="button">Cancelar
              </button>
            </div>
            <div class="col-md-12 col-lg-6 mt-1 d-flex justify-content-end">
              <button :disabled="saveChanges || isNotValid" class="btn btn-danger d-block w-100" type="button"
                      @click="createNotitificationCustomToUser">Enviar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import MazSelect from 'maz-ui/components/MazSelect';
import MazSwitch from 'maz-ui/components/MazSwitch';
import {
  createPromotions,
  disablePromotions,
  enablePromotions,
  searchPromotions,
  updatePromotions,
  uploadPhotoPromotions,
  getAllClientsForNotifications,
  createCustomNotificationPromotion
} from "@/actions";
import {srac_url_image} from "@/actions/constants";

export default {
  name: "PromocionList",
  components: {
    MazSelect,
    MazSwitch
  },
  data() {
    return {
      saveChanges: false,
      dialogNotify: false,
      objNotify: {
        title: "",
        message: "",
        promotion: "",
        allClients: true,
        clients: [],
      },

      count: 0,
      error_count: false,

      promocionesByName: [],
      search: "",
      Nuevo_Modal: false,
      Actualizar_Modal: false,
      Details_Modal: false,
      id: 0,
      operacion: "guardar",
      name: "",
      codigo: "",
      tipo_descuento: "",
      porciento: 0,
      monto: 0,
      fecha_inicio: null,
      fecha_fin: null,
      descripcion: "",

      sendNotify: false,

      error_name: "",
      error_codigo: "",
      error_tipo_descuento: "",
      error_porciento: 0,
      error_monto: 0,
      error_fecha_inicio: null,
      error_fecha_fin: null,
      error_descripcion: "",
      error_vacios: false,
      item: null,
      Photo_Modal: false,
      cliente_photo: null,
      preview: null,
      loading: false,

      clients: [],

      toast: Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        customClass: {
          popup: 'bg-white',
          title: 'text-dark'
        },
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        }
      }),
    }
  },
  mounted() {
    this.loadData();

    getAllClientsForNotifications().then(resp => {
      this.clients = resp.data.data.clientesByName.map(e => {
        return {
          value: e.id,
          label: `${e.nombre} ${e.apellidos}`
        }
      })
    }).catch(err => console.log(err));
  },
  methods: {
    openDialogNotify(item) {
      this.objNotify = {
        title: `Promoción: ${item.nombre}`,
        message: item.descripcion,
        clients: [],
        allClients: true,
        promotion: item.id,
      };
      this.dialogNotify = true;
    },

    createNotitificationCustomToUser() {
      this.saveChanges = true;
      this.objNotify.clients = this.objNotify.clients.map(e => parseInt(e));
      createCustomNotificationPromotion(this.objNotify).then(resp => {
        const {error, ok} = resp.data.data.sendNotificationCustom;
        if (ok) {
          this.resetNewNotify();
          this.dialogNotify = false;
          this.toast.fire({
            icon: "success",
            title: "Notificación enviada"
          });
          this.loadData(true);
        }
        if (error) {
          this.toast.fire({
            icon: "error",
            title: error
          });
        }
      }).catch(err => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(() => {
        this.saveChanges = false;
      });
    },

    resetNewNotify() {
      this.objNotify = {
        title: "",
        message: "",
        clients: [],
        allClients: true,
        promotion: "",
      };
      this.dialogNotify = false;
    },

    getImageUrl(imagen) {
      return srac_url_image + imagen;
    },
    loadData() {
      this.loading = true;
      searchPromotions({name: this.search}).then(resp => {
        this.promocionesByName = resp.data.data.promocionesByName;
      }).catch(err => console.log(err)).finally(() => {
        this.loading = false;
      })
    },
    Nuevo() {
      this.Nuevo_Modal = true
    },
    Actualizar(item) {
      this.name = item.nombre
      this.codigo = item.codigo
      this.count = item.count
      this.fecha_inicio = item.fechaInicio
      this.fecha_fin = item.fechaFin
      this.descripcion = item.descripcion
      this.porciento = item.porciento
      this.id = item.id
      this.Actualizar_Modal = true
    },
    Details(item) {
      this.item = item
      this.Details_Modal = true
    },
    Editar() {
      let validacion = true
      if (this.name === '') {
        this.error_name = true
        validacion = false
      }
      if (this.codigo === '') {
        this.error_codigo = true
        validacion = false
      }
      if (this.count === 0) {
        this.error_count = true
        validacion = false
      }
      if (this.descripcion === '') {
        this.error_descripcion = true
        validacion = false
      }
      if (this.fecha_inicio === null) {
        this.error_fecha_inicio = true
        validacion = false
      }
      if (this.fecha_fin === null) {
        this.error_fecha_fin = true
        validacion = false
      }
      if (this.porciento <= 0) {
        this.error_porciento = true
        validacion = false
      }
      if (validacion) {
        this.error_vacios = false;
        updatePromotions({
          nombre: this.name,
          codigo: this.codigo,
          count: this.count,
          fechaInicio: this.fecha_inicio,
          fechaFin: this.fecha_fin,
          descripcion: this.descripcion,
          porciento: this.porciento,
          id: this.id
        }).then(response => {
          if (response.data.data.updatePromocion.success) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "success",
              title: "Operación realizada con éxito"
            });
            this.Close_update();
            this.loadData();
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: response.data.data.updatePromocion.error
            });
          }
        }).catch(error => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: error
          });
        })
      } else {
        this.error_vacios = true
        return false
      }
    },
    Close_nuevo() {
      this.id = 0;
      this.name = "";
      this.count = 0;
      this.codigo = "";
      this.tipo_descuento = "";
      this.porciento = 0;
      this.monto = 0;
      this.fecha_inicio = null;
      this.fecha_fin = null;
      this.descripcion = "";
      this.sendNotify = false;
      this.Nuevo_Modal = false;
    },
    Close_update() {
      this.id = 0;
      this.name = "";
      this.codigo = "";
      this.count = 0;
      this.tipo_descuento = "";
      this.porciento = 0;
      this.monto = 0;
      this.fecha_inicio = null;
      this.fecha_fin = null;
      this.descripcion = "";
      this.sendNotify = false;
      this.Actualizar_Modal = false;
    },
    Close_details() {
      this.id = 0;
      this.name = ''
      this.descripcion = ''
      this.tarifa = 0
      this.Details_Modal = false
    },
    Crear() {
      let validacion = true
      if (this.name === '') {
        this.error_name = true
        validacion = false
      }
      if (this.codigo === '') {
        this.error_codigo = true
        validacion = false
      }
      if (this.count === 0) {
        this.error_count = true
        validacion = false
      }
      if (this.descripcion === '') {
        this.error_descripcion = true
        validacion = false
      }
      if (this.fecha_inicio === null) {
        this.error_fecha_inicio = true
        validacion = false
      }
      if (this.fecha_fin === null) {
        this.error_fecha_fin = true
        validacion = false
      }
      if (this.porciento <= 0) {
        this.error_porciento = true
        validacion = false
      }
      if (validacion) {
        this.error_vacios = false;
        createPromotions({
          nombre: this.name,
          codigo: this.codigo,
          count: this.count,
          fechaInicio: this.fecha_inicio,
          fechaFin: this.fecha_fin,
          descripcion: this.descripcion,
          porciento: this.porciento,
        }).then(response => {
          if (response.data.data.createPromocion.success) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "success",
              title: "Operación realizada con éxito"
            });
            if (this.sendNotify) {
              this.openDialogNotify(response.data.data.createPromocion.info);
            }
            this.Close_nuevo();
            this.loadData();
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: response.data.data.createPromocion.error
            });
          }
        }).catch(error => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: error
          });
        })
      } else {
        this.error_vacios = true
        return false
      }
    },
    Activar(id) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "La promoción estará disponible",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        customClass: {
          popup: 'bg-white',
          title: 'text-dark'
        },
        reverseButtons: true,
        showConfirmButton: false,
        cancelButtonText: "Cancelar",
        denyButtonText: "Sí, activar!"
      }).then(async (result) => {
        if (result.isDenied) {
          enablePromotions({
            id: parseInt(id)
          }).then(response => {
            if (response.data.data.activePromocion.success) {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                customClass: {
                  popup: 'bg-white',
                  title: 'text-dark'
                },
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "success",
                title: "Operación realizada con éxito"
              });
              this.loadData();
            } else {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                customClass: {
                  popup: 'bg-white',
                  title: 'text-dark'
                },
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "error",
                title: "Error al activar la promoción"
              });
            }
          }).catch(error => {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: error
            });
          })
        }
      })
    },
    Desactivar(id) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "La promoción no estará disponible",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        customClass: {
          popup: 'bg-white',
          title: 'text-dark'
        },
        reverseButtons: true,
        showConfirmButton: false,
        cancelButtonText: "Cancelar",
        denyButtonText: "Sí, desactivar!"
      }).then(async (result) => {
        if (result.isDenied) {
          disablePromotions({
            id: parseInt(id)
          }).then(response => {
            if (response.data.data.desactivePromocion.success) {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                customClass: {
                  popup: 'bg-white',
                  title: 'text-dark'
                },
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "success",
                title: "Operación realizada con éxito"
              });
              this.loadData();
            } else {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                customClass: {
                  popup: 'bg-white',
                  title: 'text-dark'
                },
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "error",
                title: "Error al desactivar la promoción"
              });
            }
          }).catch(error => {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              customClass: {
                popup: 'bg-white',
                title: 'text-dark'
              },
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: error
            });
          })
        }
      })
    },
    onPhoto(event) {
      const file = event.target.files[0];
      this.preview = URL.createObjectURL(file);
      const reader = new FileReader();
      reader.onload = () => {
        this.cliente_photo = reader.result
      }
      reader.readAsDataURL(file);
    },
    close_Photo() {
      this.$store.state.Cargando = false
      this.cliente_photo = null
      this.cliente_id = 0
      this.preview = null
      this.Photo_Modal = false
    },
    openPhoto(item) {
      if (item.imagen) {
        this.preview = this.getImageUrl(item.imagen);
      }
      this.id = item.id;
      this.Photo_Modal = true;
      this.error = false;
      document.body.classList.remove("modal-open");
    },
    Subir_Photo() {
      this.$store.state.Cargando = true
      if (this.cliente_photo === null) {
        this.error_vacios = true
        return false;
      }
      uploadPhotoPromotions({
        id: this.id,
        file: this.cliente_photo
      }).then(response => {
        if (response.data.data.uploadPromocionPhoto.success) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "success",
            title: "Operación realizada con éxito"
          });
          this.close_Photo();
          this.loadData();
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup: 'bg-white',
              title: 'text-dark'
            },
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: "Error al subir los archivos"
          });
        }
      }).catch(err => console.log(err));
    },
  },
  watch: {
    name(value) {
      if (value !== '') {
        if (document.getElementById('name_proteccion')) {
          document.getElementById('name_proteccion').classList.remove('is-invalid')
        }
      }
    },
    descripcion(value) {
      if (value !== '') {
        if (document.getElementById('descripcion_proteccion')) {
          document.getElementById('descripcion_proteccion').classList.remove('is-invalid')
        }
      }
    },
    tarifa(value) {
      if (value > 0) {
        if (document.getElementById('tarifa_proteccion')) {
          document.getElementById('tarifa_proteccion').classList.remove('is-invalid')
        }
      }
    }
  },

  computed: {
    isNotValid() {
      return this.objNotify.title === '' || this.objNotify.message === '' ||
          (!this.objNotify.clients || this.objNotify.clients.length === 0 && !this.objNotify.allClients);
    }
  },
};
</script>
<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  padding: 20px;
  width: 50%;
}

@media (max-width: 767px) {
  .modal-content {
    border-radius: 15px;
    padding: 10px;
    width: 90%;
  }
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

.descripcion-column {
  max-width: 200px; /* Ancho máximo para la columna */
  white-space: nowrap; /* Evita que el texto se desborde */
  overflow: hidden; /* Oculta el contenido que se desborda */
  text-overflow: ellipsis; /* Agrega puntos suspensivos (...) al final del texto recortado */
}

</style>
